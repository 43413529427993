import React, { useEffect, useState } from 'react'
import WebTemplate from '../../API/APIServices/WebTemplate';
import { useParams } from 'react-router-dom';
import TemplateSetting from '../../API/APIServices/TemplateSetting';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';

const ReportDesign = (props) => {
    const [apiData, setApiData] = useState([]);
    const [droppedItems, setDroppedItems] = useState([]);
    const [styleArray, setStylesArray] = useState([]);

    useEffect(() => {
        console.log("styleArray", styleArray);
        console.log("apiData", apiData);
        console.log("droppedItems", droppedItems);
    }, [apiData, droppedItems, styleArray]);

    const [variableReplaceData, setVariableReplaceData] = useState('')

    const showTemplateData = async (e) => {
        const filteredData = [{
            "fieldName": "name",
            "operatorName": "equal",
            "compareValue": props.templateName || "ReportDesign" || "BillPrint" || "TestReport"
        }];

        var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
        if (getdata) {
            if (getdata.jsonStructure) {
                try {
                    const decodedData = decodeURIComponent(getdata.jsonStructure);
                    const parsedData = JSON.parse(decodedData);
                    // getdata.jsonStructure = parsedData
                    setDroppedItems(parsedData);
                    if (getdata.textContents) {
                        const decodedData = decodeURIComponent(getdata.textContents)
                        const parsedData = JSON.parse(decodedData);
                        // getdata.jsonStructure = parsedData
                        setStylesArray(parsedData);
                    }
                    const orderNo = props.ord1Id || 10867;
                    let sqlQuery = getdata.insertQuery;
                    if (sqlQuery.includes('@transId')) {
                        sqlQuery = sqlQuery.replace(/@transId/g, orderNo);
                    }
                    const queryData = {
                        query1: sqlQuery,//"select ord1Id, itemName, OrderNo,ServiceTypeId,TableGroupId,TableId,PaymentStatus,ord2.itemName,D2ItemId,Qty,Rate,ord2.TotalAmt,KotStatus from ord2 inner Join ord1 on ord1.id = ord2.ord1id where o2orderno = 15",
                        query2: sqlQuery,
                        query3: sqlQuery
                    }
                    const tableData = await TemplateSetting.getMultiDatasetExecuteQuery(queryData);
                    setVariableReplaceData(tableData);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    setDroppedItems([]);
                }
            } else {
                setDroppedItems([]);
            }
            var templateInnerHtml = getdata.textContents;
            var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
            getdata.textContents = templateInnerHtmlDecode

            setApiData(getdata);
            // setDataHtml(getdata)
        } else {
            document.getElementById("drop-target-innerHtml").outerHTML = "";
            setDroppedItems([]);
        }
    }
    useEffect(() => {
        showTemplateData();
    }, []);

    useEffect(() => {
        // Use a for loop to iterate over stylesArray
        for (let index = 0; index < styleArray.length; index++) {
            const styleObject = styleArray[index];
            console.log(styleObject.id);

            const element = document.getElementById(styleObject.id);

            if (element) {
                for (const [key, value] of Object.entries(styleObject.style)) {
                    // Convert kebab-case to camelCase for inline styles
                    const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
                    element.style[camelCaseKey] = value;
                }
            }
        }
    }, [styleArray]);

    // function replacePlaceholders(itemContent, variableReplaceData) {
    //     // Iterate over each table in variableReplaceData
    //     for (const tableName in variableReplaceData) {
    //         const tableData = variableReplaceData[tableName];

    //         // Check if tableData is an array (as expected)
    //         if (Array.isArray(tableData)) {
    //             // Iterate over the objects in tableData (assuming each object contains key-value pairs for replacement)
    //             for (const obj of tableData) {
    //                 if (typeof obj === 'object' && obj !== null) { // Ensure obj is a valid object
    //                     for (const key in obj) {
    //                         if (obj.hasOwnProperty(key)) {
    //                             const regex = new RegExp(key, 'g'); // Create regex to match the placeholder
    //                             itemContent = itemContent.replace(regex, obj[key]); // Replace in itemContent
    //                         }
    //                     }
    //                 }
    //             }
    //         } else if (typeof tableData === 'object' && tableData !== null) {
    //             // Handle case where tableData is a single object instead of an array of objects
    //             for (const key in tableData) {
    //                 if (tableData.hasOwnProperty(key)) {
    //                     const regex = new RegExp(key, 'g'); // Create regex to match the placeholder
    //                     itemContent = itemContent.replace(regex, tableData[key]); // Replace in itemContent
    //                 }
    //             }
    //         }
    //         // If tableData is neither an object nor an array, it is ignored (or handled according to your needs)
    //     }
    //     return itemContent;
    // }

    // function replaceVariables(itemContent, variableReplaceData) {
    //     for (const tableName in variableReplaceData) {
    //         const tableData = variableReplaceData[tableName];

    //         // Find the first <tr> that contains <td> or is inside <tbody>
    //         const tbodyRegex = /<tbody[^>]*>(.*?)<\/tbody>/gs;
    //         let tbodyMatch = tbodyRegex.exec(itemContent);
    //         let firstRowStructure = null;

    //         if (tbodyMatch) {
    //             const tbodyContent = tbodyMatch[1];  // Content inside the first <tbody>
    //             const trRegex = /<tr>(.*?)<\/tr>/g;
    //             const firstRowMatch = trRegex.exec(tbodyContent);
    //             if (firstRowMatch) {
    //                 firstRowStructure = firstRowMatch[1]; // The first <tr> content within <tbody>
    //             }
    //         }

    //         // If no <tbody> is found, fall back to searching for the first <tr> with <td> directly
    //         if (!firstRowStructure) {
    //             const trRegex = /<tr>(.*?)<\/tr>/g;
    //             const firstRowMatch = trRegex.exec(itemContent);
    //             if (firstRowMatch) {
    //                 firstRowStructure = firstRowMatch[1]; // The first <tr> content
    //             }
    //         }

    //         // If we found a <tr> structure
    //         if (firstRowStructure) {
    //             let rowsToAdd = '';

    //             // Process replacements for each object in tableData
    //             for (const obj of tableData) {
    //                 let shouldAppend = false;

    //                 // Check if the first row (or <td>) contains placeholders matching @T1, @T2, etc.
    //                 for (const key in obj) {
    //                     if (firstRowStructure.includes(key)) {
    //                         shouldAppend = true;  // If it contains a relevant placeholder, mark for appending
    //                         break;
    //                     }
    //                 }

    //                 // If there's a match, process the row and append it
    //                 if (shouldAppend) {
    //                     let rowContent = firstRowStructure;

    //                     // Replace placeholders in the row structure with corresponding values from the current object
    //                     for (const key in obj) {
    //                         const regex = new RegExp(key, 'g');
    //                         rowContent = rowContent.replace(regex, obj[key]);
    //                     }

    //                     rowsToAdd += `<tr>${rowContent}</tr>`; // Wrap the updated row in <tr> tags
    //                 }
    //             }

    //             // Append the rows before the closing </table> tag, if rows were added
    //             if (rowsToAdd) {
    //                 itemContent = itemContent.replace('</table>', rowsToAdd + '</table>');
    //             }
    //         }

    //         // Replace any remaining placeholders in the entire content outside of the table
    //         for (const obj of tableData) {
    //             for (const key in obj) {
    //                 const regex = new RegExp(key, 'g');
    //                 itemContent = itemContent.replace(regex, obj[key]);
    //             }
    //         }
    //     }
    //     return itemContent;
    // }

    function replaceVariables(itemContent, variableReplaceData) {
        for (const tableName in variableReplaceData) {
            const tableData = variableReplaceData[tableName];

            // Find the first <tbody> and get the content inside it
            const tbodyRegex = /<tbody[^>]*>(.*?)<\/tbody>/gs;
            let tbodyMatch = tbodyRegex.exec(itemContent);
            let firstRowStructure = null;

            if (tbodyMatch) {
                const tbodyContent = tbodyMatch[1];  // Content inside the first <tbody>
                const trRegex = /<tr>(.*?)<\/tr>/g;
                const firstRowMatch = trRegex.exec(tbodyContent);
                if (firstRowMatch) {
                    firstRowStructure = firstRowMatch[1]; // The first <tr> content within <tbody>
                }
            }

            // If no <tbody> is found, fallback to searching for the first <tr> with <td> directly
            if (!firstRowStructure) {
                const trRegex = /<tr>(.*?)<\/tr>/g;
                const firstRowMatch = trRegex.exec(itemContent);
                if (firstRowMatch) {
                    firstRowStructure = firstRowMatch[1]; // The first <tr> content
                }
            }

            // If we found a <tr> structure
            if (firstRowStructure) {
                let rowsToAdd = '';
                let appendedRowIndexes = [];  // Track which row indexes have been appended
                let firstRowAppended = false;  // Track if the first row has already been appended

                // Process replacements for each object in tableData
                for (const index in tableData) {
                    const obj = tableData[index];
                    let shouldAppend = false;

                    // Skip appending if the current row is the first row and already appended
                    if (appendedRowIndexes.includes(parseInt(index))) continue;

                    // Check if the first row (or <td>) contains placeholders matching @T1, @T2, etc.
                    const tdRegex = /<td[^>]*>(.*?)<\/td>/g;
                    let tdMatch;
                    while ((tdMatch = tdRegex.exec(firstRowStructure)) !== null) {
                        const tdContent = tdMatch[1];

                        // Check if any placeholder in this <td> matches the keys in the current object
                        for (const key in obj) {
                            if (tdContent.includes(key)) {
                                shouldAppend = true;  // If it contains a relevant placeholder, mark for appending
                                break;
                            }
                        }
                        if (shouldAppend) break; // No need to check further once a match is found
                    }

                    // If there's a match, process the row and append it
                    if (shouldAppend) {
                        let rowContent = firstRowStructure;
                        // Replace placeholders in each <td> with corresponding values from the current object
                        rowContent = rowContent.replace(tdRegex, (match, p1) => {
                            for (const key in obj) {
                                if (p1.includes(key)) {
                                    return match.replace(key, obj[key]); // Replace within <td> tag
                                }
                            }
                            return match;  // Return unchanged if no matching key is found
                        });

                        // Add the updated row content to the rowsToAdd variable
                        rowsToAdd += `<tr>${rowContent}</tr>`;
                        appendedRowIndexes.push(parseInt(index));  // Mark this row as appended

                        // Mark the first row as appended after the first match
                        if (!firstRowAppended) {
                            firstRowAppended = true; // If we append a row, mark that the first row has been processed
                        }
                    }
                }

                // Remove the original first <tr> inside <tbody> if a row was appended
                if (firstRowAppended && tbodyMatch) {
                    // Remove the first <tr> inside the <tbody> only
                    const firstTrRegex = /<tr>(.*?)<\/tr>/;
                    itemContent = itemContent.replace(tbodyMatch[0], (match) => {
                        return match.replace(firstTrRegex, ''); // Remove only the first <tr> inside <tbody>
                    });
                }

                // Append the rows before the closing </table> tag, if rows were added
                if (rowsToAdd) {
                    itemContent = itemContent.replace('</table>', rowsToAdd + '</table>');
                }
            }

            // Replace any remaining placeholders in the entire content outside of the table
            for (const obj of tableData) {
                for (const key in obj) {
                    const regex = new RegExp(key, 'g');
                    itemContent = itemContent.replace(regex, obj[key]);
                }
            }
        }
        return itemContent;
    }

    const [printData, setPrintData] = useState()

    const renderDroppedItems = () => {
        return droppedItems.length > 0 && droppedItems.some(item => item.items.length > 0) ? (
            droppedItems.map((item, index) => {
                if (item.items.length > 0) {
                    const itemContent = item.textContents;
                    let updatedData;
                    if (variableReplaceData && variableReplaceData != "") {
                        updatedData = replaceVariables(itemContent, variableReplaceData);
                        // const data = replaceVariables(itemContent, variableReplaceData)
                        // updatedData = replacePlaceholders(data, variableReplaceData)
                        // generatePDF(updatedData)
                    }
                    return (
                        <div key={index} dangerouslySetInnerHTML={{ __html: updatedData }} />
                    );
                }
                return null;
            })
        ) : null;
    };

    useEffect(() => {
        if (droppedItems.length > 0) {
            droppedItems.forEach((item) => {
                if (item.items.length > 0) {
                    const itemContent = item.textContents;
                    let updatedData = null;

                    if (variableReplaceData && Object.keys(variableReplaceData).length > 0) {
                        updatedData = replaceVariables(itemContent, variableReplaceData);
                    }
                    if (updatedData) {
                        setPrintData(prevValues => prevValues + updatedData);
                    }
                }
            });
        }
    }, [droppedItems, variableReplaceData]);

    const generatePDF = async () => {
        const element = printData; //document.getElementById('pdf-content');
        if (!element) {
            console.log('Element with id "pdf_content" not found.');
            return;
        }

        // const opt = {
        //     margin: 0,
        //     filename: 'myfile.pdf',
        //     image: { type: 'jpeg', quality: 1 },
        //     html2canvas: { scale: 2 },
        //     jsPDF: { unit: 'mm', format: [230, 297], orientation: 'portrait' }
        // };
        const opt = {
            margin: 0,
            filename: 'myfile.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' } // Page width and height in mm
        };

        try {
            const pdfDataUri = await html2pdf()
                .from(element)
                .set(opt)
                .toPdf()
                .output('datauristring');

            const pdfBase64 = pdfDataUri.split(',')[1];

            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(pdfBase64);
            } else {
                // Create a blob from the base64 string
                const byteCharacters = atob(pdfBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });


                // const link = document.createElement('a');
                // link.href = URL.createObjectURL(blob);
                // link.download = 'myfile.pdf';
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);

                // // Print the PDF directly from the web
                // window.open(URL.createObjectURL(blob));

                const pdfUrl = URL.createObjectURL(blob);
                const newWindow = window.open(pdfUrl, '_blank', 'width=1000px,height=1000px');
                props.setShowBillPrint(false);

                newWindow.onload = function () {
                    newWindow.print();
                    newWindow.onafterprint = function () {
                        newWindow.close();
                    };
                };
            }
        } catch (error) {
            console.log('Error generating PDF:', error);
        }
    };

    useEffect(() => {
        if (droppedItems.length > 0) {
            const timer = setTimeout(() => {
                generatePDF();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [droppedItems, printData]);

    return (
        <>
            <button onClick={generatePDF}>Print</button>
            <div id="pdf-content">
                {renderDroppedItems()}
            </div>
        </>
    )
}

export default ReportDesign
