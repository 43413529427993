import React, { useState, useEffect, useContext, useRef } from "react";
import './DeliveryStylesheet.css';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Timer from './Timer';
import Orders from './Orders';
import Trips from './Trips';
import OrderAPI from "../../API/APIServices/Order";
import Loader from "./../../Inventory/Props/Loader";
import AlertBox from "./../../Inventory/Props/AlertBox";
import MyContext from "../../Inventory/MyContext";
import TransporterAPI from "../../API/APIServices/Transporter";
import { useNavigate } from "react-router-dom";
import Order from "../../API/APIServices/Order";
import imageCompression from 'browser-image-compression';
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Delivery = (props) => {

    const { OpenAlertBoxEdit, OpenAlertBoxDelete, setAlertBoxDelete, alertBoxDelete, setErrorMessages, webToAppLink } = useContext(MyContext);

    const navigate = useNavigate();

    const [loginUserDetails, setLoginUserDetails] = useState();

    async function getCurrentDate() {
        const isoString = await CurrentDateAndTime.getCurrentDateTime();
        const now = new Date(isoString);
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
        const day = now.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [deliveryBoyTripId, setDeliveryBoyTripId] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                setLoginUserDetails(loginUser.userName);
                console.log(loginUser.userName)

                const date = await getCurrentDate();

                const filteredData = [
                    {
                        fieldName: "delBoyId",
                        operatorName: "Equal",
                        compareValue: loginUser.userId
                    },
                    {
                        fieldName: "tripStatus",
                        operatorName: "Equal",
                        compareValue: "Open"
                    },
                    {
                        fieldName: "date",
                        operatorName: "Equal",
                        compareValue: date
                    },
                ]
                const data = await Order.getDeliveryBoyTrip(filteredData);
                console.log("data", data);
                if (data != null && data != undefined && data.length > 0) {
                    setShowCancelDelBtn(true);
                    setOpeningKmValue(data[0].openingKM);
                    //alert(data[0].vehicleId);
                    setVehicleTypeId(data[0].vehicleId);
                    setIsRunning(true);
                    setDeliveryBoyTripId(data[0].tripId);
                    setTripNo(data[0].tripNo)
                    // debugger;
                    console.log(data);
                    // const imageUrl = await OrderAPI.GettingDeliveryImage(data[0].openingKMImg);
                    const imageBaseUrl = `${WebApiUrl}/api/Order/TripImage?imageId=`;
                    console.log(imageBaseUrl);
                    setOpeningImg(`${imageBaseUrl}${data[0].openingKMImg}`)
                    console.log(data[0].openingKMImg)
                }
                else {
                    console.log("Not Found");
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [])

    const [loading, setLoading] = useState(false);

    const Tab = ({ label, icon, onClick, isActive }) => (
        <div className={`tab-del ${isActive ? "active" : ""}`} onClick={onClick}>
            <i className={`${icon} icon-del ${isActive ? "active" : ""}`}></i>&nbsp;{label}
            {/* {i} */}
        </div>
    );

    const Content = ({ children, isActive }) => (
        <div className={`content ${isActive ? "active" : ""}`}>{children}</div>
    );

    const [OrderInfoMain, setOrderInfoMain] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const storedArray = JSON.parse(storedArrayString);
        console.log(storedArray);

        const filterData = [
            {
                fieldName: "deliveryBoyId",
                operatorName: "Equal",
                compareValue: storedArray.userId
            },
            // {
            //     fieldName: "overallOrderStatus",
            //     operatorName: "NotEqual",
            //     compareValue: "Done"
            // },
            // {
            //     fieldName: "overallOrderStatus",
            //     operatorName: "NotEqual",
            //     compareValue: "Returned"
            // },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Cancel"
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Pending"
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Confirm"
            },
            // {
            //     fieldName: "overallOrderStatus",
            //     operatorName: "NotEqual",
            //     compareValue: "Delivered"
            // },
            // {
            //     fieldName: "overallOrderStatus",
            //     operatorName: "NotEqual",
            //     compareValue: "Return Request"
            // },
            {
                fieldName: "deliveryTripStatus",
                operatorName: "NotEqual",
                compareValue: "Close"
            },
            {
                fieldName: "serviceTypeId",
                operatorName: "Equal",
                compareValue: 752
            }
        ];
        try {
            // const data = await OrderAPI.getOrderReportFilter(filterData);
            const orderData1 = await OrderAPI.getRawOrderReport(filterData, 1);;
            var data = orderData1.orderList
            console.log("testdata", data);
            const sortedData = data.sort((a, b) => a.deliveryPreferenceNo - b.deliveryPreferenceNo)
            setOrderInfoMain(sortedData);
            // return sortedData;
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const refreshBtn = () => {
        setLoading(true);
        fetchData();
        setLoading(false);
        window.location.reload();
    }

    const logoutClick = () => {
        if (window.confirm("Are you sure? Your want to LogOut")) {
            localStorage.removeItem('loginAccountUserData');
            localStorage.removeItem("loginUser");
            window.location.href = `${webToAppLink}/loginpanel`;
        }
    }
    // ****************************** Data for timer ************************
    const [isRunning, setIsRunning] = useState(false);
    const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

    const [inputValues, setInputValues] = useState({
        delBoyId: 0,
        vehicleId: 0,
        vehicleType: "",
        fuelAvgLtrKM: 0,
        fuelChargeKM: 0,
        speed: 0,
        date: "",
        time: "",
        totalTime: "00:00:00",
        totalDistance: 0,
        tripIncentiveAmt: 0,
        openingKM: 0,
        closingKM: 0,
        openingKMImg: 0,
        closingKMImg: 0,
        openingTime: "00:00:00",
        closingTime: "00:00:00",
        closingDate: "",
        startLongitude: 0,
        startLatitude: 0,
        tripStatus: "",
        ordDetails: "",
    })

    // useEffect(() => {
    //     setLoading(true);
    //     try {
    //         let timer;
    //         if (isRunning) {
    //             timer = setInterval(() => {
    //                 setTime((prevTime) => {
    //                     const newTime = { ...prevTime };
    //                     newTime.seconds += 1;

    //                     if (newTime.seconds === 60) {
    //                         newTime.seconds = 0;
    //                         newTime.minutes += 1;
    //                     }

    //                     if (newTime.minutes === 60) {
    //                         newTime.minutes = 0;
    //                         newTime.hours += 1;
    //                     }

    //                     return newTime;
    //                 });
    //             }, 1000);
    //         } else {
    //             clearInterval(timer);
    //         }
    //         setLoading(false);
    //         return () => clearInterval(timer);
    //     } catch (error) {
    //        console.log(error);
    //     }
    // }, [isRunning]);

    const [openingKmValue, setOpeningKmValue] = useState(0);
    const [closingKmValue, setClosingKmValue] = useState(0);
    const [vehicleTypeId, setVehicleTypeId] = useState(0);

    const handleOpeningKmChange = (event, changingValue) => {
        const value = event.target.value
        console.log(changingValue)
        if (changingValue == "Opening") {
            setOpeningKmValue(value);
        }
        else if (changingValue == "Closing") {
            setClosingKmValue(value);
        }
    }

    async function getCurrentDateTime() {
        const isoString = await CurrentDateAndTime.getCurrentDateTime();
        const now = new Date(isoString);
        // Get current date
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const day = String(now.getDate()).padStart(2, '0');

        // Get current time
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        // Format date and time
        const currentDate = `${year}-${month}-${day}`;
        const currentTime = `${hours}:${minutes}:${seconds}`;

        return { currentDate, currentTime };
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    return { latitude, longitude }
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    const [isStartOrClose, setIsStartOrClose] = useState(false);
    const [ItemImage, setItemImage] = useState([]);

    const ItemImageUpload = (e, indx) => {
        //debugger
        const newItemImage = [...ItemImage];
        newItemImage[indx] = e;
        setItemImage(newItemImage);
    };
    const [ItemImageClosing, setItemImageClosing] = useState([]);

    const ItemImageUploadClosing = (e, indx) => {
        //debugger
        const newItemImage = [...ItemImage];
        newItemImage[indx] = e;
        setItemImageClosing(newItemImage);
    };

    const [tripNo, setTripNo] = useState();
    const [showCancelDelBtn, setShowCancelDelBtn] = useState(false);

    // **************************** Update Image *****************************
    const updateImage = async (tripNo, tripId, isOpeningClosing) => {
        const formData = new FormData();

        console.log("ItemImage", ItemImage);
        console.log(capturedImages)

        // alert(isOpeningClosing);
        if (isOpeningClosing == "OpeningKMImg") {
            ItemImage.forEach(file => {
                formData.append(`files`, file);
            });
        }
        else if (isOpeningClosing == "ClosingKMImg") {
            ItemImageClosing.forEach(file => {
                formData.append(`files`, file);
            });
        }
        formData.append(`DelBoyTripId`, tripNo);
        //formData.append(`files`, ItemImage);
        console.log(formData);

        const data = await OrderAPI.UpdateDeliveryImage(formData);
        console.log(data);
        if (data != false) {
            const splitData = data.split(":");
            const imageId = splitData[1];

            const filteredData = [{
                operationType: 0,
                path: isOpeningClosing,
                op: "Add",
                from: "string",
                value: imageId
            }]
            await OrderAPI.updateDelBoyTripPartially(filteredData, tripId);
        }
    }

    const handleStart = async () => {
        setShowCancelDelBtn(true)
        //setIsStartOrClose(true)
        console.log(closingKmValue)
        console.log(ItemImage)
        if (openingKmValue == 0) {
            alert("Please Enter Opening Km");
            return false;
        }
        else if (openingImg.length < 1) {
            alert("Please Upload Opening Km Image")
            return false;
        }
        else if (inputValues.vehicleId == 0) {
            alert("Please Select Transport Type");
            return 0;
        }
        else {
            setLoading(true);
            setIsRunning(true);
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);
            const { currentDate, currentTime } = await getCurrentDateTime();
            // alert(currentTime);
            // *****************
            let latitude = 0;
            let longitude = 0;

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        latitude = position.coords.latitude;
                        longitude = position.coords.longitude;
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                    }
                );
            } else {
                alert('Geolocation is not supported by this browser.');
            }
            //alert(`latitude- ${latitude}, longitude=${longitude}`)

            // **************
            const orderDetails = [];
            for (var i = 0; i < OrderInfoMain.length; i++) {
                orderDetails.push(OrderInfoMain[i].orderNo);
            }
            const orderDetailsString = orderDetails.join(',');

            setInputValues((prevValues) => ({
                ...prevValues,
                "delBoyId": loginUser.userId,
                "date": currentDate,
                "time": currentTime,
                "openingTime": currentTime,
                "startLongitude": longitude,
                "startLatitude": latitude,
                "closingDate": currentDate,
                "openingKM": openingKmValue,
                "ordDetails": orderDetailsString,
            }));

            const updatedData = {
                ...inputValues,
                "delBoyId": loginUser.userId,
                "date": currentDate,
                "time": currentTime,
                "openingTime": currentTime,
                "startLongitude": longitude,
                "startLatitude": latitude,
                "closingDate": currentDate,
                "openingKM": openingKmValue,
                "ordDetails": orderDetailsString,
            }
            console.log(updatedData);
            debugger;
            const deliveryData = await OrderAPI.createDeliveryTrip(updatedData, OrderInfoMain)
            console.log("deliveryData", deliveryData)

            const tripId = deliveryData.tripNo;   //tripId
            setTripNo(tripId);

            alert(`Your trip no - ${tripId}`);

            await updateImage(tripId, deliveryData.id, "OpeningKMImg");
            // *************** Update Order Partially ***************
            for (var i = 0; i > OrderInfoMain.length; i++) {
                const updatedValues = [
                    {
                        operationType: 0,
                        path: "DeliveryBoyTripNo",
                        op: "Add",
                        from: "string",
                        value: tripId
                    },
                    {
                        operationType: 0,
                        path: "DeliveryStatus",
                        op: "Add",
                        from: "string",
                        value: "Open"
                    },
                ]
                await Order.updateOrderPartially(updatedValues, OrderInfoMain[i].ord1Id);
            }
            setLoading(false);
            alert("Your Trip has been Started");
            hideModal();
        };
    }

    const handleStop = async () => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        const isAllItemDelivered = OrderInfoMain.filter(item => item.overallOrderStatus != "Delivered"
            && item.overallOrderStatus != "Returned")
        //alert(isAllItemDelivered.length)
        if (closingImg.length == 0) {
            alert("Please Upload Closing Km Image")
            return false;
        }
        else {
            if (closingKmValue == 0) {
                alert("Please Enter Closing Km");
                return false;
            }
            else if (isAllItemDelivered.length > 0) {
                alert("Please Delivered all orders");
                return false;
            }
            else {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                setIsRunning(false);

                const { currentDate, currentTime } = await getCurrentDateTime();
                const hour = String(time.hours).padStart(2, '0');
                const minutes = String(time.minutes).padStart(2, '0');
                const seconds = String(time.seconds).padStart(2, '0');

                const totalTime = `${hour}:${minutes}:${seconds}`

                const filteredData = [
                    {
                        operationType: 0,
                        path: "totalTime",
                        op: "Add",
                        from: "string",
                        value: totalTime
                    },
                    {
                        operationType: 0,
                        path: "tripStatus",
                        op: "Add",
                        from: "string",
                        value: "Close"
                    },
                    {
                        operationType: 0,
                        path: "closingDate",
                        op: "Add",
                        from: "string",
                        value: currentDate
                    },
                    {
                        operationType: 0,
                        path: "closingTime",
                        op: "Add",
                        from: "string",
                        value: currentTime
                    },
                    {
                        operationType: 0,
                        path: "closingKm",
                        op: "Add",
                        from: "string",
                        value: closingKmValue
                    },
                ]
                await OrderAPI.updateDelBoyTripPartially(filteredData, deliveryBoyTripId);
                setTime({ hours: 0, minutes: 0, seconds: 0 });

                await updateImage(tripNo, deliveryBoyTripId, "ClosingKMImg");
                // *********** Update Trip Status *************
                for (var i = 0; i < OrderInfoMain.length; i++) {
                    const updatedValues = [
                        {
                            operationType: 0,
                            path: "DeliveryTripStatus",
                            op: "Add",
                            from: "string",
                            value: "Close"
                        },]
                    await Order.updateOrderPartially(updatedValues, OrderInfoMain[i].ord1Id);
                }
                alert("Your Trip has been Stopped");
                hideModal()
            }
            // ****************************************
            const data = await Order.getDelIncentiveAmt(loginUser.userId, tripNo)
        }
        window.location.reload();
    };

    const handlePause = () => {
        setIsRunning(false);
    };

    const [isModalOpen, setModalOpen] = useState(false)

    const openBox = (startStop) => {
        if (OrderInfoMain.length == 0) {
            alert("No Pending Deliveries for you");
            return 0;
        }
        if (startStop == "Start") {
            setIsStartOrClose(true);
        }
        else if (startStop == "Stop") {
            setIsStartOrClose(false);
        }
        setModalOpen(true);
    }
    const hideModal = () => {
        setModalOpen(false);
    }
    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    // *****************************{Image Capture}******************************
    const inputRef = useRef(null);
    const [capturedImages, setCapturedImages] = useState([]);
    const [openingImg, setOpeningImg] = useState([]);
    const [closingImg, setClosingImg] = useState([]);
    // const [showDeleteButton, setShowDeleteButton] = useState(true);

    const handleCapture = (event) => {

        if (inputRef.current) {
            inputRef.current.click();
        }
        // const file = event.target.files[0];
        // ItemImageUpload(file)
    };

    const handleOpeningFileChange = async (event) => {
        const file = event.target.files[0];
        const fileSizeInBytes = file.size;
        const fileSizeInMb = fileSizeInBytes / 1000000; // Convert bytes to MB
        alert(`Image size: ${fileSizeInBytes} bytes (${fileSizeInMb.toFixed(2)} MB)`);

        if (fileSizeInMb > 5) {
            alert("File size exceeds 5 MB. Compressing the file...");

            const options = {
                maxSizeMB: 4,          // Set the maximum size to 4 MB
                maxWidthOrHeight: 1920, // Set a max width or height (optional)
                useWebWorker: true,     // Use web worker for compression
            };

            try {
                const compressedFile = await imageCompression(file, options);
                const compressedFileSizeInMb = compressedFile.size / 1000000;
                console.log(`Compressed image size: ${compressedFile.size} bytes (${compressedFileSizeInMb.toFixed(2)} MB)`);

                if (compressedFileSizeInMb < 3 || compressedFileSizeInMb > 4) {
                    alert("Unable to compress the file to the desired size. Please choose a smaller file.");
                    return;
                }

                const imageUrl = URL.createObjectURL(compressedFile);
                setOpeningImg([imageUrl]);
                ItemImageUpload(compressedFile, 0);
            } catch (error) {
                console.error("Error compressing the file:", error);
            }
        } else {
            const imageUrl = URL.createObjectURL(file);
            setOpeningImg([imageUrl]);
            ItemImageUpload(file, 0);
        }
    };

    const handleClosingFileChange = async (event) => {
        const file = event.target.files[0];
        const fileSizeInBytes = file.size;
        const fileSizeInMb = fileSizeInBytes / 1000000; // Convert bytes to MB
        alert(`Image size: ${fileSizeInBytes} bytes (${fileSizeInMb.toFixed(2)} MB)`);

        if (fileSizeInMb > 5) {
            alert("File size exceeds 5 MB. Compressing the file...");

            const options = {
                maxSizeMB: 4,          // Set the maximum size to 4 MB
                maxWidthOrHeight: 1920, // Set a max width or height (optional)
                useWebWorker: true,     // Use web worker for compression
            };

            try {
                const compressedFile = await imageCompression(file, options);
                const compressedFileSizeInMb = compressedFile.size / 1000000;
                console.log(`Compressed image size: ${compressedFile.size} bytes (${compressedFileSizeInMb.toFixed(2)} MB)`);

                if (compressedFileSizeInMb < 3 || compressedFileSizeInMb > 4) {
                    alert("Unable to compress the file to the desired size. Please choose a smaller file.");
                    return;
                }

                const imageUrl = URL.createObjectURL(compressedFile);
                setOpeningImg([imageUrl]);
                ItemImageUpload(compressedFile, 0);
            } catch (error) {
                console.error("Error compressing the file:", error);
            }
        } else {
            const imageUrl = URL.createObjectURL(file);
            setClosingImg([imageUrl]);
            ItemImageUploadClosing(file, 0);
        }
    };

    const handleDelete = (index) => {
        setCapturedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const [vehicleType, setVehicleType] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await TransporterAPI.GetTransporter();
                setVehicleType(data);
            } catch (error) {
                alert(error);
            }
        };
        fetchData();
    }, []);

    // *************************
    const [images, setImages] = useState([]);

    const handleImageChange = (e) => {
        // *************************** For Multiple Image Upload **********************
        const files = Array.from(e.target.files);
        if (isStartOrClose === false) {
            // Iterate through selected files
            files.forEach((file) => {
                const reader = new FileReader();

                reader.onload = () => {
                    const imageDataUrl = reader.result;
                    setImages((prevImages) => [...prevImages, imageDataUrl]);
                };

                reader.readAsDataURL(file);
            });
            ItemImageUpload(files, 1)
        }
        else {
            // ***************************** For Single Image Uplaod *********************
            let newImages = [];
            if (files.length > 0) {
                files.forEach((file, index) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        newImages.push(reader.result);
                        // Check if all files have been processed
                        if (newImages.length === files.length) {
                            // Now set the state with all new images
                            setImages(newImages);
                        }
                    };
                    reader.readAsDataURL(file);
                });
            } else {
                // No files selected, clear images
                setImages([]);
            }
        }
    };
    // const handleTouchStart = (e) => {
    // };

    // const handleTouchMove = (e) => {
    //     setLoading(true)
    // };

    // const handleTouchEnd = () => {
    //     fetchData();
    //     setLoading(false)
    // };

    const handleFocus = (event) => {
        event.target.select();
    };

    const [isOpen, setIsOpen] = useState(false);

    const settingBtn = async () => {
        setIsOpen(!isOpen)
    }

    const handleVehicleTypeChange = (e) => {
        setVehicleTypeId(e.target.value)
        if (e.target.value == "default") {
            setInputValues((prevValues) => ({
                ...prevValues,
                "vehicleId": 0,
                "vehicleType": "",
                "fuelAvgLtrKM": 0,
                "fuelChargeKM": 0,
            }));
        }
        else {
            const data = vehicleType.filter(item => item.id == e.target.value);
            setInputValues((prevValues) => ({
                ...prevValues,
                "vehicleId": e.target.value,
                "vehicleType": data[0].name,
                "fuelAvgLtrKM": data[0].fuelAvgLtrKM,
                "fuelChargeKM": data[0].fuelChargeKM,
            }));
        }
    }

    return (
        <div style={{ backgroundColor: "white" }}>
            <div className="tabs-container-del">
                <div className="tabs-del" style={{ backgorundColor: "steelblue" }}>
                    <Tab style={{ color: "white" }}
                        icon="fa-solid fa-house"
                        label="HOME"
                        onClick={() => handleTabClick(1)}
                        isActive={activeTab === 1}
                    />
                    <Tab
                        style={{ color: "white" }}
                        icon="fa-solid fa-bowl-rice"
                        label="ORDERS"
                        onClick={() => handleTabClick(2)}
                        isActive={activeTab === 2}
                    />
                    <Tab
                        style={{ color: "white" }}
                        icon="fa-solid fa-circle-user"
                        label="TRIPS"
                        onClick={() => handleTabClick(3)}
                        isActive={activeTab === 3}
                    />
                </div>
                <div className="tab-content height500px" style={{ backgroundColor: "white" }}>
                    {activeTab === 1 && (
                        <Content isActive={activeTab === 1} style={{ backgroundColor: "white", }}>
                            <div className="" style={{ backgroundColor: "white", }}>
                                <div className="p-1">
                                    <div className="flex-start" style={{ paddingLeft: "15px" }}>
                                        <label className="ques" style={{ fontSize: "20px" }}>User: &nbsp;</label>
                                        <label className="ans" style={{ fontSize: "20px" }}>{loginUserDetails ? loginUserDetails : ""}</label>
                                    </div>
                                    <div className="flex-start" style={{ paddingLeft: "15px" }}>
                                        <label className="ques" style={{ fontSize: "20px" }}>Distance : &nbsp;</label>
                                        <label className="ans" style={{ fontSize: "20px" }}>0.00 KM</label>
                                    </div>
                                    <div className="flex-start" style={{ paddingLeft: "15px" }}>
                                        <label className="ques" style={{ fontSize: "20px" }}>Speed : &nbsp;</label>
                                        <label className="ans" style={{ fontSize: "20px" }}>0.00 KM/H</label>
                                    </div>
                                    {OrderInfoMain.length != 0 ? (
                                        <>
                                            <Timer OrderInfoMain={OrderInfoMain} time={time} openBox={openBox} isRunning={isRunning} handlePause={handlePause}
                                                isModalOpen={isModalOpen} hideModal={hideModal} handleModalClick={handleModalClick} openingKmValue={openingKmValue}
                                                handleOpeningKmChange={handleOpeningKmChange} closingKmValue={closingKmValue} handleCapture={handleCapture} handleOpeningFileChange={handleOpeningFileChange}
                                                handleClosingFileChange={handleClosingFileChange}
                                                inputRef={inputRef} capturedImages={capturedImages} handleDelete={handleDelete} handleImageChange={handleImageChange}
                                                images={images} isStartOrClose={isStartOrClose} handleStart={handleStart} handleStop={handleStop} />
                                        </>) : (
                                        <></>
                                    )}
                                    <div className="flex-center mt-3">
                                        <label className="ques"> Version: &nbsp;</label>
                                        <label className="ans">1.4</label>
                                        <label className="ques">&nbsp; Publish : &nbsp;</label>
                                        <label className="ans">06/11/2023</label>
                                    </div>
                                </div>
                            </div>
                        </Content>
                    )}
                    {activeTab === 2 && (
                        <Content isActive={activeTab === 2} style={{ padding: "0px" }}>
                            <div style={{ backgroundColor: "white", padding: "-20px" }}>
                                <Orders OrderInfoMain={OrderInfoMain} tripNo={tripNo} showCancelDelBtn={showCancelDelBtn} fetchData={fetchData} isStartOrClose={isStartOrClose} setOrderInfoMain={setOrderInfoMain} />
                            </div>
                        </Content>
                    )}
                    {activeTab === 3 && (
                        <Content isActive={activeTab === 3}>
                            <div style={{ backgroundColor: "white" }}>
                                <Trips />
                            </div>
                        </Content>
                    )}
                </div>
            </div>
            <div className="floating-button-container" style={{ bottom: "30px" }}>
                <div className="circle bg-black" style={{}} onClick={refreshBtn} >
                    <i class="fa-solid fa-rotate icon-white"></i>
                </div>
                {isOpen && (
                    <div className="dropdown-content-floating">
                        <Link to="/OrderWiseTrackingReport" style={{ textDecoration: "none" }}><div>Order Wise Tracking Report</div></Link>
                        <hr style={{ margin: "0px", color: "#bda6a6" }} />
                        <Link to="/TripWiseDelBoyTrackingReport" style={{ textDecoration: "none" }}><div>Trip Wise Tracking Report</div></Link>
                        <hr style={{ margin: "0px", color: "#bda6a6" }} />
                        <div>My Document</div>
                    </div>
                )}
                <div className="circle bg-black mt-2" style={{}} onClick={settingBtn} >
                    <i class="fa-solid fa-gear icon-white"></i>
                </div>
                <div className="circle bg-black mt-2" style={{}} onClick={logoutClick}>
                    <i class="fa-solid fa-power-off icon-white"></i>
                </div>
            </div>
            {isModalOpen && (
                <div className="modal-back-del" >
                    <div className="timerTrip-modal">
                        <div className="Del_MainHead" >
                            <div className="modal-title5">
                                <h3 className="modalTableTitleColor mt-2">My Delivery Trip</h3>
                                {/* <span className="closeTableModal5" onClick={hideModal}>
                                &times;
                            </span> */}
                            </div>
                            <div className="modal-content-im">
                                <div className="table_main_01" style={{ paddingBottom: "0px" }}>
                                    <div className="input-box-Im ">
                                        {/* <div className="input-box-mok1"> */}
                                        {/* <select className="modify_im_input mode_category1" type="text" required>
                                                <option>--Select--</option> 
                                                <option value={7845788574} >7845788574</option>
                                                <option value={9876543210}>9876543210</option>
                                            </select> */}
                                        {/* <input className="modify_im_input mode_category1" type="text" disabled value="9876543210" required />
                                            <label className="modify_lbl_text3">Mobile No</label>
                                        </div> */}
                                    </div>
                                    {/* <div className="input-box-m1">
                                        <select className="modify_im_input mode_limit mode_category" ref={input5Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input6Ref)} onChange={(e) => handleInputChange(e)} type="text" required value={inputValues.state} name="state">
                                            <option Classname="form-control s-1" value="">Select state</option>
                                            {(() => {
                                                if (stateInfo !== null && Array.isArray(stateInfo)) {  // && Array.isArray(stateInfo)
                                                    return stateInfo.map((data) => (
                                                        // console.log(data.stateName),
                                                        <option key={data.id} value={data.id}>
                                                            {data.stateName}
                                                        </option>
                                                    ))
                                                }
                                            })()}

                                        </select>
                                        <label className="modify_lbl_text4">State</label>
                                    </div> */}
                                    <div className="input-box-Im ">
                                        <div className="input-box-mok1">
                                            <select className="modify_im_input mode_category1" type="text" required
                                                onChange={handleVehicleTypeChange} value={vehicleTypeId} disabled={isStartOrClose === false ? true : false}>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (vehicleType !== null && Array.isArray(vehicleType)) {
                                                        return vehicleType.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            {/* <input className="modify_im_input mode_category1" type="text" disabled value="Bike" required /> */}
                                            <label className="modify_lbl_text3">Vehicle</label>
                                        </div>
                                    </div>
                                    <div className="input-box-Im ">
                                        <div className="input-box-mok1">
                                            <input className="modify_im_input mode_limit mode_category1" type="number" name="openingKmValue" value={openingKmValue} onChange={(e) => handleOpeningKmChange(e, "Opening")}
                                                required onFocus={(e) => handleFocus(e)} disabled={isStartOrClose === false ? true : false} onClick={(e) => handleFocus(e)} />
                                            <label class="modify_lbl_text3">Opening KM <span class="StarClr">*</span></label>
                                        </div>
                                    </div>

                                    {isStartOrClose === true ? (
                                        <></>
                                    ) : (<div className="input-box-Im ">
                                        <div className="input-box-mok1">
                                            <input className="modify_im_input mode_limit mode_category1" type="number" name="closingKmValue" value={closingKmValue} onChange={(e) => handleOpeningKmChange(e, "Closing")}
                                                required onFocus={(e) => handleFocus(e)} onClick={(e) => handleFocus(e)} />
                                            <label class="modify_lbl_text3">Closing KM </label>
                                        </div>
                                    </div>)}
                                    <div className="input-box-Im ">
                                        <div className="input-box-mok1" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                            <div onClick={() => handleCapture()} className="camerabox">
                                                <i class="fa-solid fa-camera" style={{ fontSize: "21px", margin: "5px" }}></i>
                                            </div>
                                            <div className="ImageShow5">
                                                {isStartOrClose ? <input
                                                    type="file"
                                                    accept="image/*"
                                                    capture="environment"
                                                    onChange={handleOpeningFileChange}
                                                    ref={inputRef} style={{ display: 'none' }}
                                                /> :
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        capture="environment"
                                                        onChange={handleClosingFileChange}
                                                        ref={inputRef} style={{ display: 'none' }}
                                                    />}

                                                {/* <button onClick={handleCapture}>Capture Photo</button> */}
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                                                        {/* {openingImg.map((imageUrl, index) => ( */}
                                                        {/* <div key={index} style={{ margin: '5px', position: 'relative' }}> */}
                                                        {/* <div className="delete-button DeleteBtnImg" onClick={() => handleDelete(index)}>
                                                                <i className="fa-solid fa-xmark IconImage5"></i>
                                                            </div> */}
                                                        {openingImg != null && openingImg != "" && openingImg != undefined ?
                                                            <img src={openingImg} alt={`Captured ${1}`} style={{ width: '80px', height: '80px', margin: "5px", borderRadius: "5px" }} />
                                                            :
                                                            <div></div>
                                                        }
                                                        {/* </div> */}
                                                        {/* ))} */}
                                                    </div>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                                                        {/* {closingImg.map((imageUrl, index) => ( */}
                                                        {/* <div key={index} style={{ margin: '5px', position: 'relative' }}> */}
                                                        {/* <div className="delete-button DeleteBtnImg" onClick={() => handleDelete(index)}>
                                                                <i className="fa-solid fa-xmark IconImage5"></i>
                                                            </div> */}
                                                        {closingImg != null && closingImg != "" && closingImg != undefined ?
                                                            <img src={closingImg} alt={`Captured ${2}`} style={{ width: '80px', height: '80px', margin: "5px", borderRadius: "5px" }} />
                                                            :
                                                            <div></div>
                                                        }
                                                        {/* </div> */}
                                                        {/* ))} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {/* <input type="file" accept="image/*" multiple onChange={handleImageChange} /> */}
                                <div>
                                    {images.map((image, index) => (
                                        <img key={index} src={image} alt={`Image ${index}`} style={{ width: '100px', height: '100px', margin: '5px' }} />
                                    ))}
                                </div>
                            </div>
                            <hr />
                            <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                {isStartOrClose ?
                                    <button className="greenBtn" style={{ width: "50%" }} onClick={() => handleStart()}>Start My Trip</button>
                                    :
                                    <button className="greenBtn" style={{ width: "50%" }} onClick={() => handleStop()}>Close My Trip</button>
                                }

                                <button className="redBtn" style={{ width: "50%" }} onClick={hideModal}>Close</button>
                            </div>

                            {/* <div className="buttom-box5">
                                <div className="button-btn-5">
                                    <button onClick={handleStart} className="btn-1"></button>
                                </div>
                                <div className="button-btn-5">
                                    <button onClick={hideModal} className="btn-2">Close</button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            )}
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={props.OrderCancel}
                />
            )}
            {loading && <Loader />}
        </div>
    );
};

export default Delivery;
