// import React, { useState } from "react";
import { useEffect, useState, useRef } from "react";
import "./OrderTakerStylesheet.css";
import Order from "../../API/APIServices/Order";
import { Await, useLocation, useNavigate } from "react-router-dom";
// import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from "../../Inventory/Props/Loader";
import Company from "../../API/APIServices/Company";
import { use } from "i18next";
import Account from "../../API/APIServices/Account";
import GlobalFunction from "../../API/APIServices/GlobalFunction";
import html2pdf from 'html2pdf.js';
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

function OrderTakerBillPrint(props) {
    const location = useLocation();
    // const { id, paymentAmt, balanceAmt, grandTotal, paymentStatus, orderStatus } = location.state || {}

    let id, paymentAmt, balanceAmt, grandTotal, paymentStatus, orderStatus, isOnloadPrint;

    if (location.state && location.state.id) {
        ({ id } = location.state);
    } else {
        ({ id, isOnloadPrint } = props); {/*paymentAmt, balanceAmt, grandTotal, paymentStatus, orderStatus*/ }
    }
    console.log("location.state.id", id);

    const searchParams = new URLSearchParams(location.search);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [Data, setData] = useState([]);

    const [userData, setUserData] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalCancelAmount, setTotalCancelAmount] = useState(0);

    useEffect(() => {
        const fetchBillData = async () => {
            setLoading(true);
            try {
                const filterData = {
                    fieldName: "ord1Id",
                    operatorName: "equal",
                    compareValue: id,
                };
                if (id != 0 && id != null && id != undefined && id != "") {
                    const data = await Order.getOrderListByOrd1Id(id);
                    var billdata = data.item1
                    setData(billdata);
                    console.log("billdata", billdata);

                    const total = billdata && billdata.reduce((sum, data) => sum + (data.qty * data.rate), 0);
                    setTotalAmount(total);
                    //alert(billdata[0].d1AccId);
                    const UserData = await Account.getAccountById(billdata[0].d1AccId);
                    console.log(UserData.item1[0])
                    setUserData(UserData.item1[0]);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        fetchBillData();
    }, []);

    const [currentDate, setCurrentDate] = useState();
    const [currentTime, setCurrentTime] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const isoString = await CurrentDateAndTime.getCurrentDateTime();
            const currentDate = new Date(isoString);

            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const date = currentDate.getDate();

            const hours = currentDate.getHours();
            const minutes = currentDate.getMinutes();
            const seconds = currentDate.getSeconds();

            const amOrPm = hours >= 12 ? 'PM' : 'AM';

            const formattedHours = hours % 12 || 12;

            const formattedMinutes = minutes.toString().padStart(2, '0');
            const formattedSeconds = seconds.toString().padStart(2, '0');

            const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${amOrPm}`;
            const formattedDate = `${date}-${month}-${year}`;

            setCurrentDate(formattedDate);
            setCurrentTime(formattedTime);
        }
        fetchData();


    }, [])

    let prevOrderId = null;

    const [currentDateTime, setCurrentDateTime] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const date = await getCurrentDateTime1();
            setCurrentDateTime(date);
        }
        fetchData();
    }, [])

    async function getCurrentDateTime1() {
        // setLoading(true);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        const isoString = await CurrentDateAndTime.getCurrentDateTime();
        const now = new Date(isoString);

        const dayOfWeek = days[now.getDay()];
        const month = months[now.getMonth()];
        const dayOfMonth = now.getDate();
        const year = now.getFullYear();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();

        // setLoading(false);
        return `${dayOfWeek} ${month} ${dayOfMonth} ${year} - ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
    }

    function formatTime(time) {
        return time < 10 ? `0${time}` : time;
    }

    const [companyData, setCompanyData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                const data = await Company.GetCompanyById(loginUser.compId)
                console.log(data);
                setCompanyData(data)
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchData();
    }, [])

    // const generatePDF = async () => {
    //     document.getElementById("displayBlock").style.display = "block";
    //     const element = document.getElementById("pdf_content");

    //     const opt = {
    //         margin: 0,
    //         filename: 'myfile.pdf',
    //         image: { type: 'png' },
    //         html2canvas: { scale: 4 },
    //         jsPDF: { unit: 'mm', format: [58, 700], orientation: 'portrait' } // Page width and height in mm
    //     };

    //     html2pdf()
    //         .from(element)
    //         .set(opt)
    //         .toPdf()
    //         .output('datauristring')
    //         .then(pdfDataUri => {
    //             const pdfBase64 = pdfDataUri.split(',')[1];

    //             // Check if running in a WebView
    //             if (window.ReactNativeWebView) {
    //                 window.ReactNativeWebView.postMessage(pdfBase64);
    //             } else {
    //                 // Create a blob from the base64 string
    //                 const byteCharacters = atob(pdfBase64);
    //                 const byteNumbers = new Array(byteCharacters.length);
    //                 for (let i = 0; i < byteCharacters.length; i++) {
    //                     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //                 }
    //                 const byteArray = new Uint8Array(byteNumbers);
    //                 const blob = new Blob([byteArray], { type: 'application/pdf' });

    //                 // Create a link element and click it to download the PDF
    //                 // const link = document.createElement('a');
    //                 // link.href = URL.createObjectURL(blob);
    //                 // link.download = 'myfile.pdf';
    //                 // document.body.appendChild(link);
    //                 // link.click();
    //                 // document.body.removeChild(link);

    //                 // // Print the PDF directly from the web
    //                 // window.open(URL.createObjectURL(blob));

    //                 // const pdfUrl = URL.createObjectURL(blob);
    //                 // const newWindow = window.open(pdfUrl);
    //                 // newWindow.onload = function () {
    //                 //     newWindow.print();
    //                 //     newWindow.onafterprint = function () {
    //                 //         newWindow.close();
    //                 //     };
    //                 // };
    //                 const iframe = document.createElement('iframe');
    //                 iframe.style.display = 'none';
    //                 document.body.appendChild(iframe);

    //                 const pdfUrl = URL.createObjectURL(blob);
    //                 iframe.src = pdfUrl;

    //                 iframe.onload = function () {
    //                     // Trigger the print dialog once the PDF is loaded
    //                     iframe.contentWindow.print();
    //                     // Remove the iframe after printing
    //                     setTimeout(() => {
    //                         document.body.removeChild(iframe);
    //                     }, 1000); // Allow some time for the print dialog to appear before removing
    //                 };
    //                 // alert("Print");
    //             }
    //         })
    //         .catch(error => {
    //             alert('Error generating Print:', error);
    //         });
    // };

    const generatePDF = async () => {
        setLoading(true);
        const element = document.getElementById("pdf_content");

        if (!element) {
            console.log('Element with id "pdf_content" not found.');
            return;
        }
        const opt = {
            margin: 0,
            filename: 'myfile.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' } // Page width and height in mm
        };

        try {
            const pdfDataUri = await html2pdf()
                .from(element)
                .set(opt)
                .toPdf()
                .output('datauristring');

            const pdfBase64 = pdfDataUri.split(',')[1];

            let file;
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(pdfBase64);
            } else {
                // Create a blob from the base64 string
                const byteCharacters = atob(pdfBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                file = new File([blob], 'myfile.pdf', {
                    type: 'application/pdf',
                    lastModified: Date.now()
                });

                console.log('File metadata:', {
                    lastModified: file.lastModified,
                    lastModifiedDate: file.lastModifiedDate,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    webkitRelativePath: file.webkitRelativePath
                });

                // const link = document.createElement('a');
                // link.href = URL.createObjectURL(blob);
                // link.download = 'myfile.pdf';
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);

                // // Print the PDF directly from the web
                // window.open(URL.createObjectURL(blob));

                const pdfUrl = URL.createObjectURL(blob);
                const newWindow = window.open(pdfUrl);
                newWindow.onload = function () {
                    newWindow.print();
                    newWindow.onafterprint = function () {
                        newWindow.close();
                    };
                };
                setHasExecuted(false);
            }
        } catch (error) {
            console.log('Error generating PDF:', error);
        }
        setLoading(false)
    };

    const handleBackBtn = () => {
        if (props.backBtn == "modal") {
            props.closePrintModal();
        }
        else {
            window.history.back();
        }
    }

    const [lastExecuted, setLastExecuted] = useState(0);
    const [hasExecuted, setHasExecuted] = useState(false); // Track if the action has been executed

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (isOnloadPrint && !hasExecuted) {
                setLoading(true);

                await generatePDF();
                await handleBackBtn();

                setLastExecuted(Date.now());
                setHasExecuted(true); // Set flag to true after execution
                setLoading(false);
            }
        }, 3000); // Wait for 3 seconds before executing

        return () => clearTimeout(timer); // Clear timeout on component unmount
    }, [isOnloadPrint, hasExecuted]);


    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await Order.getCancelItemByOrd1Id(id);
            const groupedData = data.reduce((acc, item) => {
                if (!acc[item.itemOrd2Id]) {
                    acc[item.itemOrd2Id] = { ...item, totalApprovedQty: (item.cancelApprovedQty ? item.cancelApprovedQty : 0) };
                } else {
                    acc[item.itemOrd2Id].totalApprovedQty += item.cancelApprovedQty;
                }
                return acc;
            }, {});

            const filteredData = Object.values(groupedData);

            const total = filteredData.reduce((sum, data) => sum + (data.totalApprovedQty * data.itemPrice), 0);
            setTotalCancelAmount(total);

            console.log(filteredData);
            setFilteredData(filteredData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    function convertTimeTo12HourFormat(timeString) {
        const [hours, minutes, seconds] = timeString.split(':').map(num => parseInt(num, 10));
        const period = hours >= 12 ? 'pm' : 'am';
        const hours12 = hours % 12 || 12;
        const formattedTime = `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;

        return formattedTime;
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    function formatDate(inputDate) {
        const parts = inputDate.split('-');
        const dateObject = new Date(parts[0], parts[1] - 1, parts[2]);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const divs = [1, 2];

    return (
        <div style={{ fontFamily: "courier", fontWeight: "700", fontSize: "16px" }}>
            {Data && Data.filter((data) => {
                const isDifferentOrderId = data.ord1Id !== prevOrderId;
                prevOrderId = data.ord1Id;
                return isDifferentOrderId;
            }).map((order) => (
                <div
                    className="MHut-Bg-border"
                    style={{ backgroundColor: "rgb(33, 46, 79)" }}
                >
                    <div className="flex-center-MHut" style={{ display: "flex", justifyContent: "space-between" }}>
                        <i className="fa-solid fa-arrow-left icon-white" style={{ color: "white", cursor: "pointer", marginLeft: "10px" }} onClick={handleBackBtn}></i>
                        <label className="MHut-text printLabel">
                            <u className="MHut-underline printLabel" style={{ fontFamily: "courier" }}>{companyData && companyData?.printName}</u>
                        </label>
                        <i className="fa-solid fa-print" onClick={generatePDF} style={{ color: "white", marginRight: "10px" }} />
                    </div>
                    <div id="pdf_content">
                        {divs.map((item, index) => (
                            <div style={{ marginTop: "10px" }}>
                                <div className="MHut-GST-Border">
                                    <div style={{ display: "block" }} id="displayBlock">
                                        <div style={{ display: "flex", justifyContent: "center", marginBottom: "8px", borderBottom: "1px solid black" }}>
                                            <label className="MHut-text" style={{ color: "black", textAlign: "center", fontSize: "20px" }}>
                                                <label className="MHut-underline" style={{ fontFamily: "calibri" }}>{companyData && companyData?.printName}</label>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start">
                                            <label className="ques-text-col printLabel-dark" style={{ fontFamily: "courier" }}>Company GST No : &nbsp;</label>
                                            <label className="ans printLabel" style={{ fontFamily: "courier" }}>{companyData && companyData?.gstTinNo}</label>
                                        </div>
                                    </div>
                                    <div className="flex-start">
                                        <label className="ques-text-col printLabel-dark">FSSAI No : &nbsp;</label>
                                        <label className="ans printLabel">{companyData && companyData?.cstNo}</label>
                                    </div>
                                </div>
                                <div className="MHut-GST-Border">
                                    <div className=""> {/*space-between*/}
                                        <div className="flex-start MHut-width-bill " style={{ width: "100%" }}>
                                            <label className="ques-text-col printLabel-dark">Name : &nbsp;</label>
                                            <label className="ans printLabel">{Data == undefined ? "" : userData.name}</label>
                                        </div>
                                        <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                                            <label className="ques-text-col printLabel-dark">Mobile No : &nbsp;</label>
                                            <label className="ans printLabel">{userData == undefined ? "" : userData.mobileNo}</label>
                                        </div>
                                    </div>
                                    {/***************/}
                                    <div className=""> {/*space-between*/}
                                        <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                                            <label className="ques-text-col printLabel-dark">Order No : &nbsp;</label>

                                            <label className="ans printLabel">{order.orderNo}</label>
                                        </div>
                                        <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                                            <label className="ques-text-col printLabel-dark">Date : &nbsp;</label>
                                            <label className="ans printLabel">{moment(order.orderDate).format('DD-MM-YYYY')}</label>
                                        </div>
                                    </div>
                                    <div className=""> {/*space-between*/}
                                    </div>
                                    <div className=""> {/*space-between*/}
                                        <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                                            <label className="ques-text-col printLabel-dark">Service Type : &nbsp;</label>
                                            <label className="ans printLabel">{order.serviceTypeName}</label>
                                        </div>
                                        <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                                            <label className="ques-text-col printLabel-dark">Company&nbsp;Name:&nbsp;</label>
                                            <label className="ans printLabel">{Data == undefined ? "" : Data[0].companyDetails}</label>
                                        </div>
                                    </div>
                                    <div className=""> {/*space-between*/}
                                        <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                                            <label className="ques-text-col printLabel-dark">Table : &nbsp;</label>
                                            <label className="ans printLabel">{order.tableName}</label>
                                        </div>
                                        <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                                            <label className="ques-text-col printLabel-dark">Gst No : &nbsp;</label>
                                            <label className="ans printLabel">{Data == undefined ? "" : Data[0].companyGSTNo}</label>
                                        </div>
                                    </div>
                                    <div className=""> {/*space-between*/}
                                        <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                                            <label className="ques-text-col printLabel-dark">Time Slot : &nbsp;</label>
                                            <label className="ans printLabel">{Data && Data != null && Data != undefined && Data.length > 0 && formatDate(Data[0].deliveryDate)} , {Data && Data != null && Data != undefined && Data.length > 0 && (Data[0].timeSlotName)}</label>{/*deliveryTime*/}
                                        </div>
                                        {order.serviceTypeId == 752 && (
                                            <div className="flex-start MHut-width-bill" style={{ width: "100%" }}>
                                                <label className="ques-text-col printLabel-dark">KDTT : &nbsp;</label>
                                                <label className="ans printLabel">{Data && Data != null && Data != undefined && Data.length > 0 && convertTimeTo12HourFormat(Data[0].kotDispatchTargetTime)}</label>
                                            </div>
                                        )}
                                    </div>
                                    {/*************************/}
                                </div>
                                {/**************************************************************************/}
                                <div className="MHut-GST-Border">
                                    <table className="table-bill-hd">
                                        <thead className="thead-groupmaster">
                                            <tr>
                                                <th className="table-bill-MHut printLabel-dark" style={{ width: "10%" }}>
                                                    Sno
                                                </th>
                                                <th
                                                    className="table-bill-MHut item-left-bill printLabel-dark"
                                                    style={{ width: "40%" }}
                                                >
                                                    Item
                                                </th>
                                                <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                                                    Qty
                                                </th>
                                                <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                                                    Rate
                                                </th>
                                                <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                                                    Amt
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Data && Data.map((data, index) => (
                                                <tr key={data.id}>
                                                    <td className="MHut-Bill-text printLabel-dark">{index + 1}</td>
                                                    <td className="MHut-Bill-text item-left-bill printLabel-dark">{data.d2ItemName}</td>
                                                    <td className="MHut-Bill-text printLabel-dark">{data.qty}</td>
                                                    <td className="MHut-Bill-text printLabel-dark">{data.rate}</td>
                                                    <td className="MHut-Bill-text printLabel-dark">{data.qty * data.rate}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="flex-end mt-1" style={{ marginRight: "10px" }}>
                                        <label className="printLabel-dark" style={{ fontSize: "14px", fontWeight: "600" }}>Total Order Amt :&nbsp;</label>
                                        <label className="printLabel" style={{ fontSize: "14px", }}>₹ {totalAmount}</label>
                                    </div>
                                </div>
                                {/* ************************** Cancel Order Item *********************** */}
                                <div className="MHut-GST-Border" style={{ display: filteredData.length == 0 ? "none" : "block" }}>
                                    <label className="MHut-Bill-text printLabel-dark"><u>Cancel Item Details :-</u></label>{" "}
                                    <table className="table-bill-hd">
                                        <thead className="thead-groupmaster">
                                            <tr>
                                                <th className="table-bill-MHut printLabel-dark" style={{ width: "10%" }}>
                                                    Sno
                                                </th>
                                                <th
                                                    className="table-bill-MHut item-left-bill printLabel-dark"
                                                    style={{ width: "40%" }}
                                                >
                                                    Item
                                                </th>
                                                <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                                                    Qty
                                                </th>
                                                <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                                                    Rate
                                                </th>
                                                <th className="table-bill-MHut printLabel-dark" style={{ width: "15%" }}>
                                                    Amt
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData && filteredData
                                                .filter(item => item.totalApprovedQty != 0)
                                                .map((data, index) => (
                                                    <tr key={data.id}>
                                                        <td className="MHut-Bill-text printLabel-dark">{index + 1}</td>
                                                        <td className="MHut-Bill-text item-left-bill printLabel-dark">{data.itemName}</td>
                                                        <td className="MHut-Bill-text printLabel-dark">{data.totalApprovedQty}</td>
                                                        <td className="MHut-Bill-text printLabel-dark">{data.itemPrice}</td>
                                                        <td className="MHut-Bill-text printLabel-dark">{data.totalApprovedQty * data.itemPrice}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                    <div className="flex-end mt-1" style={{ marginRight: "10px" }}>
                                        <label className="printLabel-dark" style={{ fontSize: "14px", fontWeight: "600" }}>Total Cancellation Amt :&nbsp;</label>
                                        <label className="printLabel-dark" style={{ fontSize: "14px", }}>₹ {totalCancelAmount}</label>
                                    </div>
                                </div>
                                {/****************/}
                                <div className="MHut-GST-Border">
                                    <div className="" style={{ justifyContent: '' }}>
                                        <div>
                                            <label className="ques-text-col" style={{ color: "darkorange" }}>
                                                &#9733;
                                            </label>
                                            <label className="ques-text-col printLabel-dark">&nbsp;Bill include of GST &nbsp;</label>
                                        </div>
                                        <label className="ques-text-col printLabel-dark">FINAL BILL AMOUNT : ₹{totalAmount - totalCancelAmount}</label>
                                    </div>
                                    <div className="flex-start" style={{ justifyContent: 'space-between' }}>
                                        <label className="ques-text-col printLabel-dark" style={{ width: "100%" }}>
                                            Print&nbsp;on&nbsp;{currentDateTime}&nbsp;
                                        </label>
                                    </div>
                                </div>
                                <div className="printLabel MHut-GST-Border" style={{ textAlign: "center", color: "black", padding: "10px", backgroundColor: "white" }}>We're grateful for your visit. Thank you !</div>
                            </div>
                        ))}
                        {/* *********************** Kot Print ******************** */}
                        {Data.map((item, index) => (
                            <div style={{ marginTop: "10px" }}>
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center", marginBottom: "8px", borderBottom: "1px solid black", backgroundColor: "White" }}>
                                        <label className="MHut-text" style={{ color: "black", textAlign: "center", fontSize: "20px" }}>
                                            <label className="MHut-underline" style={{ fontFamily: "calibri" }}>{companyData && companyData?.printName}</label>
                                        </label>
                                    </div>
                                </div>
                                <div className="MHut-color-KOT">
                                    <div className="space-between">
                                        <div className="flex-start MHut-width-KOT">
                                            <label className="ques-text-col">Order&nbsp;No&nbsp;:&nbsp;</label>
                                            <label className="ans printLabel">{item.orderNo}</label>
                                        </div>
                                    </div>
                                    <div className="flex-start MHut-width-KOT" style={{ width: "100%" }}>
                                        <label className="ques-text-col">Item&nbsp;Name:&nbsp;</label>
                                        <label className="ans printLabel">{item.d2ItemName}</label>
                                    </div>
                                    {/***************/}
                                    <div className="space-between">
                                        <div className="flex-start MHut-width-KOT">
                                            <label className="ques-text-col">Item&nbsp;Category:&nbsp;</label>
                                            <label className="ans printLabel">Testing</label>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start MHut-width-KOT">
                                            <label className="ques-text-col printLabel-dark">SKU&nbsp;:&nbsp;</label>
                                            <label className="ans printLabel">{item.sku}</label>
                                        </div>
                                    </div>
                                    <div className="space-between">
                                        <div className="flex-start MHut-width-KOT">
                                            <label className="ques-text-col">Serve&nbsp;Pref.&nbsp;:&nbsp;</label>
                                            <label className="ans printLabel">{item.preferenceNo}</label>
                                        </div>
                                    </div>
                                    <div className="flex-start MHut-width-KOT">
                                        <label className="ques-text-col">Qty&nbsp;:&nbsp;</label>
                                        <label className="ans printLabel">{item.qty}</label>
                                    </div>
                                    <div className="flex-start MHut-width-KOT">
                                        <label className="ques-text-col">Item Suggestion :&nbsp;</label>
                                        <label className="ans printLabel">{item.itemSuggestion}</label>
                                    </div>
                                    <div className="flex-start MHut-width-KOT">
                                        <label className="ques-text-col">KOT Manager Name :&nbsp;</label>
                                        <label className="ans printLabel">{item.kitchenManagerName}</label>
                                    </div>
                                    <div className="flex-start MHut-width-KOT" style={{ width: "100%" }}>
                                        <label className="ques-text-col">Print&nbsp;Date&nbsp;:&nbsp;</label>
                                        <label className="ans printLabel">{currentDate}</label>
                                    </div>
                                    <div className="flex-start MHut-width-KOT" style={{ width: "100%" }}>
                                        <label className="ques-text-col">&&nbsp;Time&nbsp;: &nbsp;</label>
                                        <label className="ans printLabel">{currentTime}</label>
                                    </div>
                                    <div>
                                        <div className="space-between">
                                            <div className="flex-start MHut-width-KOT">
                                                <label className="ques-text-col">Time Slot :&nbsp;</label>
                                                <label className="ans printLabel">{item.timeSlotName}</label>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <div className="flex-start MHut-width-KOT">
                                                <label className="ques-text-col">KDTT Time :</label>
                                                <label className="ans printLabel">{convertTimeTo12HourFormat(item.kotDispatchTargetTime)}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/*************************/}
                                </div>
                            </div>
                        ))}
                        {/* ****************** */}
                    </div>
                </div>
            ))}
            {loading && <Loader />}
        </div>
    );
}

export default OrderTakerBillPrint;
