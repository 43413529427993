import { useContext, useEffect, useState } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import Company from "../../../API/APIServices/Company";
import UserCredential from "../../../API/APIServices/UserCredential";
import FormNameInfo from "../../../API/APIServices/FormNameInfo";
import User_Authentication from "../../../API/APIServices/UserAuthentication";
import { useNavigate } from 'react-router-dom';
import LoginType from "../../../API/APIServices/LoginType";

function UserAuthentication() {

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit, alertBoxErrorUpdate, alertBoxErrorInsert, OpenAlertBoxErrorInsert, OpenAlertBoxErrorUpdate } =
        useContext(MyContext);

    const navigate = useNavigate();
    const [fileDataArray, setFileDataArray] = useState([]);

    //************** company and user name load **************/

    const [inputValues, setInputValues] = useState({
        companyName: "",
        userName: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        fetchUserList(value);
    };

    const handleInputChangeUser = async (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        fetchModuleList(value);

        /*********** Fetch User Authenticated Files ***********/
        setEditMode(false);
        var userData = await User_Authentication.getUserAuthenticationById(value);
        if (userData != null) {

            if (userData) {
                var fileArray = [];
                userData.forEach(obj => {
                    const value1 = obj.formId;
                    const value2 = obj.formName;
                    const value3 = obj.moduleName;
                    const newObj = {
                        "id": value1,
                        "formName": value2,
                        "moduleName": value3,
                    };

                    fileArray.push(newObj);
                });
                //debugger;
                //console.log(fileArray)
                setFileDataArray(fileArray);
            }
            setEditMode(true);
        }
    };


    const handleLoginTypeChange = async (event) => {
        if (event.target.value == "default") {
            fetchUserList();
        }
        else {
            const filterData = [
                {
                    fieldName: "branchId",
                    operatorName: "Equal",
                    compareValue: inputValues.companyName
                },
                {
                    fieldName: "userRole",
                    operatorName: "Equal",
                    compareValue: event.target.value
                },
            ]
            const data = await UserCredential.getFilteredUserCredential(filterData);
            setUserList(data);
        }
    }

    //************End modal box************/ /

    const [companyList, setCompanyList] = useState([]);

    useEffect(() => {
        const fetchCompData = async () => {
            try {
                const data = await Company.GetCompany();
                // console.log(data);
                setCompanyList(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchCompData();
        fetchLoginTypeList();
    }, []);

    const [userList, setUserList] = useState([]);

    const fetchUserList = async (id) => {
        try {
            const filteredData = [
                {
                    "fieldName": "branchId",
                    "operatorName": "Equal",
                    "compareValue": id
                },
            ]
            const data = await UserCredential.getFilteredUserCredential(filteredData);
            // console.log(data);
            setUserList(data);
        } catch (error) {
            // console.error("Error fetching company data:", error);
        }
    };
    const [loginType, setLoginType] = useState([]);

    const fetchLoginTypeList = async () => {
        try {
            const data = await LoginType.getLoginType();
            setLoginType(data);
        } catch (error) {
            console.log("Error fetching Login Type data:", error);
        }
    };

    const [moduleList, setModuleList] = useState([]);

    const fetchModuleList = async (id) => {
        try {
            const data = await FormNameInfo.getFormNameInfo();

            var valuesArray = [];
            data.forEach(obj => {
                const value = obj.moduleName;
                const newObj = { "moduleName": value };
                const exists = valuesArray.some(item => item.moduleName === newObj.moduleName);
                if (!exists) {
                    valuesArray.push(newObj);
                }
            });

            setModuleList(valuesArray);
        } catch (error) {
            // console.error("Error fetching company data:", error);
        }
    };


    const [isShowDetails, setShowDetails] = useState(Array(moduleList.length).fill(false));

    const showFormInfoModal = async (value, index) => {
        setShowDetails(prevState => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index]; // Toggle the state for the clicked row

            if (updatedState[index]) {
                fetchFileList(value, index);
            }
            return updatedState;
        });
    };


    const [fileList, setFileList] = useState([])

    const fetchFileList = async (value, index) => {
        try {
            const filteredData = [
                {
                    "fieldName": "moduleName",
                    "operatorName": "Equal",
                    "compareValue": value
                },
            ]
            const data = await FormNameInfo.getFilteredFormNameInfo(filteredData);

            const updatedFileList = [...fileList];
            updatedFileList[index] = data;
            setFileList(updatedFileList);
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };

    const [checkboxes2, setCheckboxes2] = useState(Array(fileList.length).fill(false));

    const handleCheckboxChange2 = (id) => {
        setCheckboxes2(prevCheckboxes => {
            const updatedState = [...prevCheckboxes];
            updatedState[id] = !updatedState[id]; // Toggle the state for the clicked row
            return updatedState;
        });

        setFileDataArray(prevData => {
            const existingIndex = prevData.findIndex(item => item.id === id);

            if (existingIndex !== -1) {
                // If the id already exists, delete the corresponding object
                const newData = prevData.filter(item => item.id !== id);
                return newData;
            } else {
                // If the id does not exist, add the new object to the array
                const newObj = {
                    "id": id,
                    "formName": '',
                    "moduleName": ''
                };
                return [...prevData, newObj];
            }
        });

    };


    const [checkboxes, setCheckboxes] = useState(Array(moduleList.length).fill(false));
    //setCheckboxes(false);
    const handleCheckboxChange = (checked, index, id) => {

        setCheckboxes(prevCheckboxes => {
            const updatedState = [...prevCheckboxes];
            updatedState[index] = checked; // Toggle the state for the clicked row
            return updatedState;
        });

        setCheckboxes2(Array(fileList.length).fill(!false));

        if (checked === true) {
            // If the checkbox is checked, add all data available in fileList
            setFileDataArray(prevData => {
                const newData = [...prevData]; // Copy the previous data

                // Iterate over fileList and add each item to newData if it's not already present
                fileList[index].forEach(item => {
                    if (!newData.some(existingItem => existingItem.id === item.id)) {
                        newData.push({
                            "id": item.id,
                            "formName": item.formName,
                            "moduleName": item.moduleName
                        });
                    }
                });

                return newData; // Return the updated data
            });
        } else {
            // If the checkbox is unchecked, delete the corresponding object from fileDataArray if it exists
            setFileDataArray(prevData => {
                const newData = prevData.filter(item => !fileList[index].some(fileItem => fileItem.id === item.id));
                return newData;
            });
        }

    };

    const handleSelectAll = (event) => {
        const allChecked = Object.values(checkboxes).every(isChecked => isChecked);

        // Toggle the state of checkboxes to either allChecked or !allChecked
        const updatedCheckboxes = Array(moduleList.length).fill(!allChecked);
        setCheckboxes(updatedCheckboxes);

        // Toggle the state of checkboxes2 to either allChecked or !allChecked
        const updatedCheckboxes2 = Array(fileList.length).fill(!allChecked);
        setCheckboxes2(updatedCheckboxes2);

        // Update fileDataArray based on the state of allChecked
        setFileDataArray(prevData => {
            if (allChecked) {
                // If allChecked is false, clear fileDataArray
                return [];
            } else {
                console.log(fileList);
                // If allChecked is true, add all data available in fileList to fileDataArray
                const newData = fileList.reduce((acc, currentFileList) => {
                    currentFileList.forEach(item => {
                        if (!acc.some(existingItem => existingItem.id === item.id)) {
                            acc.push({
                                "id": item.id,
                                "formName": item.formName,
                                "moduleName": item.moduleName
                            });
                        }
                    });
                    return acc;
                }, []);
                return newData;
            }
        });
    };

    const [inputUserAuthentication, setInputUserAuthentication] = useState([{
        branchId: 0,
        userId: 0,
        modulId: 0,
        formId: 0
    }]);
    const [isEditMode, setEditMode] = useState(true)
    const [errorMessages, setErrorMessages] = useState({
        Error: "",
    });

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {

        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['companyName', 'userName'];


    const SaveBtnUserAuthentication = async () => {
        console.log(fileDataArray)

        /*const flattenedFileList = fileList.flatMap(innerArray => innerArray);
        const inputUserAuthentication =
            flattenedFileList.map((file) => ({
                branchId: Number(inputValues.companyName),
                userId: Number(inputValues.userName),
                modulId: 0,
                formId: file.id
            }));*/

        const inputUserAuthentication = fileDataArray.map((file) => ({
            branchId: Number(inputValues.companyName),
            userId: Number(inputValues.userName),
            modulId: 0,
            formId: file.id
        }));

        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(isEditMode) === true) {

                const data = await User_Authentication.updateUserAuthentication(inputUserAuthentication);
                console.log(data);
                if (data) {
                    alert("Update successfull");
                } else {
                    alert("Error while updating");
                }
            }
            else {
                setLoading(true);
                const data = await User_Authentication.insertUserAuthentication(inputUserAuthentication);
                console.log(data);
                if (data) {
                    alert("Save successfull");
                } else {
                    alert("Error while saving");
                }
            }
            setLoading(false);
            setInputUserAuthentication([{
                branchId: 0,
                userId: 0,
                modulId: 0,
                formId: 0
            }]);
        }
    }

    //*********************{Button Script}*********************//
    return (
        <div className="" >
            < PageTabs />
            <div className="nav-1" >
                <div className="panel-heading">
                    <h2 className="panel-title">User Authentication</h2>
                    <MainButton SaveButton={SaveBtnUserAuthentication} PageName="Masters" />

                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="search_panel_hd1">
                            <div className="search_panel_left_side">
                                <div className="col-sm-no1">
                                    <div className="group">
                                        <div className="input-box-mo1">
                                            <select className="modify_im_input mode_category" name="companyName" value={inputValues.companyName} required onChange={handleInputChange}>
                                                <option className="s-1 form-control" value="" >
                                                    -Select-
                                                </option>
                                                {companyList.map((company, index) => (
                                                    <option className="s-1 form-control" value={company.id} key={index}>{company.name}</option>
                                                ))}
                                            </select>
                                            <label className="modify_lbl_text3">Firm Name</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="search_panel_left_side">
                                <div className="col-sm-no1">
                                    <div className="group">
                                        <div className="input-box-mo1">
                                            <select className="modify_im_input mode_category" name="userName" required onChange={handleLoginTypeChange}>
                                                <option className="s-1 form-control" value="default">
                                                    -Select-
                                                </option>
                                                {loginType && loginType.map((user, index) => (
                                                    <option className="s-1 form-control" value={user.id} key={index}>{user.name}</option>
                                                ))}
                                            </select>
                                            <label className="modify_lbl_text3">User Role</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="search_panel_left_side">
                                <div className="col-sm-no1">
                                    <div className="group">
                                        <div className="input-box-mo1">
                                            <select className="modify_im_input mode_category" name="userName" value={inputValues.userName} required onChange={handleInputChangeUser}>
                                                <option className="s-1 form-control" value="default">
                                                    -Select-
                                                </option>
                                                {userList && userList.map((user, index) => (
                                                    <option className="s-1 form-control" value={user.id} branchId={user.branchId} key={index}>{user.userName}</option>
                                                ))}
                                            </select>
                                            <label className="modify_lbl_text3">User Name</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="search_panel_left_side">
                                <div className="col-sm-no1">
                                    <div className="group">
                                        <svg className="search_panel_icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
                                        <input placeholder="Search Menu Name" type="search" className="search_panel_input search_option" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mt-2" >
                            <div className="table-resposive" >
                                <table id="tblTransactionList" className="table_hdo2 table-bordered1" frame="box">
                                    <thead textAlign="center" className="thead_scroll_col1" >
                                        <tr className="">
                                            <th className="td_col5"> <div className="table_head_mmb ">
                                                <div className="table_head_mmb1">S.No.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb"><input id="chkAll" type="checkbox" className="check_box" checked={Object.values(checkboxes).every((isChecked) => isChecked)}
                                                onChange={handleSelectAll} />
                                                <div className="table_head_mmbb1">Select All</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1">Module Name</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col5"> <div className="table_head_mmb">
                                                <div className="table_head_mmb1"> Authentication Details</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                        </tr>
                                    </thead>
                                    <tbody className="tbody_body_m1">
                                        {moduleList.map((data1, index1) => (
                                            <tr className="table_tbody_tr1" key={index1}>
                                                <td className="td_col5">{Number(index1) + 1}</td>
                                                <td className="td_col5" style={{ textAlign: "start" }}>
                                                    <input type="checkbox" className="check_box"
                                                        checked={checkboxes[index1]}
                                                        //checked={Object.values(checkboxes2).every((isChecked) => isChecked)}
                                                        onChange={(e) => handleCheckboxChange(e.target.checked, index1, data1.id)} /></td>
                                                <td className="td_col5" style={{ textalign: "start" }}>{data1.moduleName}</td>
                                                <td className="td_col6" style={{ textalign: "center" }}>
                                                    <p className="user_color"
                                                        onClick={(e) => showFormInfoModal(data1.moduleName, index1)}
                                                        name="moduleName"
                                                    >Show Details</p>
                                                    {isShowDetails[index1] && (
                                                        <div className="show_main_col">
                                                            <div className="col-sm-a12">
                                                                <div className="table-resposive" >
                                                                    <table id="tblTransactionList" className="table_hdo2 table-bordered1" frame="box">
                                                                        <thead textAlign="center" className="thead_scroll_col1 user-heading">
                                                                            <tr className="">
                                                                                <th className="td_col5"> <div className="table_head_mmb ">
                                                                                    <div className="table_head_mmbo1">S.No.</div>
                                                                                </div> </th>
                                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                                    <div className="table_head_mmbo1">All</div>
                                                                                </div> </th>
                                                                                <th className="td_col5"> <div className="table_head_mmb">
                                                                                    <div className="table_head_mmbo1">Description</div>
                                                                                </div> </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="tbody_body_m1">
                                                                            {fileList[index1] && fileList[index1].map((data2, index2) => (
                                                                                <tr className="table_tbody_tr1" key={index1}>
                                                                                    <td className="td_col5">{Number(index2) + 1}</td>
                                                                                    <td className="td_col5" style={{ textAlign: "start" }}>
                                                                                        <input type="checkbox" className="check_box"
                                                                                            checked={fileDataArray.some(item => item.id === data2.id)}
                                                                                            onChange={() => handleCheckboxChange2(data2.id)}
                                                                                        />
                                                                                        <input type="hidden" value={data2.id} name="id" />
                                                                                    </td>
                                                                                    <td className="td_col5" style={{ textalign: "start" }}>{data2.formName}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {alertBoxErrorInsert && (
                <AlertBox
                    HeadingTop="ERROR"
                    Heading="Error While inserting Data"
                    onYesClick={OpenAlertBoxErrorInsert}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxErrorInsert}
                />
            )}

            {loading && <Loader />}
        </div >

    );
}

export default UserAuthentication;
