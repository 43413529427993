import React, { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css'
import { Link } from "react-router-dom";
import Order from "../../API/APIServices/Order";
import UserCredential from "../../API/APIServices/UserCredential";
import Loader from "../../Inventory/Props/Loader";
import Company from "../../API/APIServices/Company";
import AppHeader from '../AppHeader';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import MyContext from "../../Inventory/MyContext";

function MHCHEFList() {
    //*************************title Start***********************//
    useState(() => {
        document.title = 'CHEF';
    }, []);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [chefList, setChefList] = useState();
    const [counting, setCounting] = useState(0);
    const [isRunningOrder, setIsRunningOrder] = useState("Chef App");

    const { setAppToWebLink, setWebToAppLink, setAutoTransferToKitchen, setCompanyData, } = useContext(MyContext);

    const fetchData = async () => {
        setLoading(true);
        try {
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);
            const userData = await UserCredential.getUserCredentialById(loginUser.userId)
            const data = await Order.getOrderItemDepartmentWise(userData.restItemDpartmentId, loginUser.userId)
            const userDetail = await UserCredential.getUserCredentialById(loginUser.userId);
            const runnignData = await Order.getRunningOrderItemDepartmentWise(userDetail.restItemDpartmentId, loginUser.userId);
            const length = runnignData ? runnignData.length : 0;
            const isRunningOrder = localStorage.getItem("isRuningOrder")
            // if (isRunningOrder == "Chef App") {
            //     setChefList(data);
            //     setIsRunningOrder("Chef App");
            // }
            // else 
            if (isRunningOrder == "Chef App-Running Order") {
                setChefList(runnignData);
                setIsRunningOrder("Chef App-Running Order");
            }
            else {
                setChefList(data);
                setIsRunningOrder("Chef App");
            }
            setCounting(length);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const sidebar = () => {
        try {
            document.getElementById("sidebar1").style.left = "0px";
            if (document.getElementById("sidebar1").style.left === "0px") {
                document.getElementById('scrollBar').classList.remove('scroll-container');
                document.getElementById('scrollBar').style.overflow = "hidden";
            }
        }
        catch { }
    }
    const sidebarclose = () => {
        try {
            document.getElementById("sidebar1").style.left = "-300px";
            document.getElementById("body").classList.toggle("back-blur");
            document.getElementById('scrollBar').classList.add('scroll-container');
            document.getElementById('scrollBar').style.overflow = "scroll";
        }
        catch { }
    }

    const [loginUserDetails, setLoginUserDetails] = useState({});
    const [firmName, setFirmName] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                setLoginUserDetails(loginUser);
                const data = await Company.GetCompanyById(loginUser.compId);
                setFirmName(data.name);

                setAppToWebLink(data.urL1);
                setWebToAppLink(data.urL2);
                setAutoTransferToKitchen(data.isEnblAutoTransferToKitchen);
                setCompanyData(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const RunningOrderDetail = async () => {
        setLoading(true)
        const userDetail = await UserCredential.getUserCredentialById(loginUserDetails.userId);
        const data = await Order.getRunningOrderItemDepartmentWise(userDetail.restItemDpartmentId, loginUserDetails.userId)
        setChefList(data);
        setIsRunningOrder("Chef App-Running Order")
        localStorage.setItem("isRuningOrder", "Chef App-Running Order")
        sidebarclose();
        setLoading(false);
    }

    const chefListData = async () => {
        setLoading(true)
        const userDetail = await UserCredential.getUserCredentialById(loginUserDetails.userId);
        const data = await Order.getOrderItemDepartmentWise(userDetail.restItemDpartmentId, loginUserDetails.userId)
        setChefList(data);
        setIsRunningOrder("Chef App")
        localStorage.setItem("isRuningOrder", "Chef App")
        sidebarclose();
        setLoading(false);
    }

    return (
        <div>
            <div>
                <AppHeader title={isRunningOrder} link={"/ChefList"} bellIcon={isRunningOrder == "Chef App" ? true : false} counting={counting} handleBellIcon={RunningOrderDetail} setLoading={setLoading} fetchData={fetchData} sidebar={sidebar} />
                <div className="content_box" onClick={sidebarclose} style={{ marginTop: "45px" }}>
                    <div className="company-info-top-im">
                        <div className="tab-head">
                            <div id="tabs">
                                <div className="tab-content-box">
                                    <div className="tab-content-1" style={{ marginTop: "55px" }}>
                                        {chefList && chefList.length > 0 ? (
                                            chefList.map((data, index) => (
                                                <div key={index} className="company-info-top-left" >
                                                    <div className="company_info-text-im td-1" style={{ marginBottom: "0px" }}>{data.itemName}</div>
                                                    <div className="col-sm-12">
                                                        <div className="table-resposive">
                                                            <div className="flex-MH">
                                                                <Link to={`/ChefDetail?status=Complete&d2ItemId=${data.d2ItemId}`} style={{ textDecoration: "none" }} className="MHBoxDiv MH-Box Bg-Completed Link-underline">
                                                                    <div>
                                                                        <div className="Flex-T-MH">
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">T</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">=</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH Bg-0-elipse">{data.tableCompleteQty}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Flex-P-MH">
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">P</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">=</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH ">
                                                                                <label className="fs-121-MH Bg-0-elipse">{data.parcelCompleteQty}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="Flex-Center-MH">
                                                                            <label className="fs-121-MH">COMPLETE</label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                <Link to={`/ChefDetail?status=Process&d2ItemId=${data.d2ItemId}`} style={{ textDecoration: "none" }} className="MHBoxDiv MH-Box Bg-Process Link-underline">
                                                                    <div>
                                                                        <div className="Flex-T-MH">
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">T</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">=</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH Bg-0-elipse">{data.tableProcessQty}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Flex-P-MH">
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">P</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">=</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH Bg-0-elipse">{data.parcelProcessQty}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="Flex-Center-MH">
                                                                            <label className="fs-121-MH">PROCESS</label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                <Link to={`/ChefDetail?status=Queue&d2ItemId=${data.d2ItemId}`} style={{ textDecoration: "none" }} className="MHBoxDiv MH-Box Bg-Queue Link-underline">
                                                                    <div>
                                                                        <div className="Flex-T-MH">
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">T</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">=</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH Bg-0-elipse">{data.tableQueueQty}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Flex-P-MH">
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">P</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH">=</label>
                                                                            </div>
                                                                            <div className="Flex-Center-MH">
                                                                                <label className="fs-121-MH Bg-0-elipse">{data.parcelQueueQty}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="Flex-Center-MH">
                                                                            <label className="fs-121-MH">QUEUE</label>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{ margin: "0px" }} />
                                                </div>
                                            ))
                                        ) : (
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "baseline" }}>
                                                <label>No Item available.</label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*############################################### Side Bar #######################################*/}
            <div className='backbtn'>
                <div id="" className="wrapper1">
                    <div className="">
                        <div id="sidebar1" className="sidebar1">
                            <div className="space-between side-topbar">
                                <div className='flex-start'>
                                    <div className="img_icon">
                                        <img className="userProfileIcon" src="https://clipart-library.com/images/kTKo7BB8c.png" alt="user" />
                                    </div>
                                    <div className="" style={{ margin: "7px 10px auto" }}>
                                        <h5 className="c-w">{loginUserDetails ? loginUserDetails.userName : ""}</h5>
                                        <p className="c-w" style={{ marginTop: "-8px" }}>{firmName}</p>
                                    </div>
                                </div>
                                <p className="back_button" onClick={sidebarclose} style={{ color: "white", textAlign: "end", padding: "8PX" }}>
                                    <i className="fa-solid fa-xmark" style={{ color: "white", fontSize: "22px" }}></i>
                                </p>
                            </div>
                            <div className="side-bottomBar">
                                <div className="flex-start linksDiv" onClick={chefListData}>
                                    <i className="fa-solid fa-bullseye"></i>
                                    <label className=""> &nbsp; Chef List</label>
                                </div><hr />
                                <div className="flex-start linksDiv" onClick={RunningOrderDetail}>
                                    <i className="fa-solid fa-chair"></i>
                                    <label className=""> &nbsp; Running Order</label>
                                </div><hr />
                                {/* <div className="flex-start linksDiv">
                                    <i className="fa-solid fa-n"></i>
                                    <label className=""> &nbsp; New Order</label>
                                </div><hr /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default MHCHEFList;
