import React, { useContext, useState, useEffect } from "react";
import Button from "./Button";
import TwoColumn from "./TwoColumn";
import ThreeColumn from "./ThreeColumn";
import ThreeAndSevenColumn from "./ThreeAndSevenColumn";
import Link from "./Link";
import Image from "./Image";
import Video from "./Video";
import Map from "./Map";
import LinkBlock from "./LinkBlock";
import Quote from "./Quote";
import TextSection from "./TextSection";
import Form from "./Form";
import Input from "./Input";
import TextArea from "./TextArea";
import Select from "./Select";
import Label from "./Label";
import CheckBox from "./CheckBox";
import Radio from "./Radio";
import CountDown from "./CountDown";
import Tabs from "./Tabs";
import ToolTip from "./ToolTip";
import Typed from "./Typed";
import ImageSlider from "./ImageSlider";
import Text from "./Text";
import IconLibrary from "./IconLibrary";
import ToggleBox from "./ToggleBox";
// import Calendar from "./Calendar";
import AlertBox from "./AlertBox";
import IconBox from "./IconBox";
import SearchBox from "./SearchBox";
import ColumnOne from "./ColumnOne";
import Box from "./Box";
import Footer from "./Footer";
import LogoModal from "./LogoBox";
import NavBarTypes from "./NavBarTypes";
// import CustomCode from "./CustomCode";
import MyContext from "../../../Inventory/MyContext";
import CustomCode from "./CustonCode";
import Calendar from "./Calender";
import Paragraph from "./Paragraph";
import Heading from "./Heading";
import TextBox from "./TextBox";
import Table from "./Table";
import VerticalLine from "./VerticalLine";
import HorizontalLine from "./HorizontalLine";
import RupeeSymbol from "./RupeeSymbol";
import Square from "./Square";
// import LogoModal from "./LogoBox";
// import CustomCode from "./CustonCode";
import TableHeader from "./TableHeader";
import TableDetail from "./TableDetail";
import TableFooter from "./TableFooter";

const ColumnOneMain = (props) => {
  const { setTemplateData, droppedItems, setDroppedItems, childComponentValue, setChildComponentValue, setClickedItemId, setClickedItemName, setStylesArray } = useContext(MyContext);
  const [hoveredComponentId, setHoveredComponentId] = useState(null);
  const componentMap = {
    "1": ColumnOne,
    "2": TwoColumn,
    "3": ThreeColumn,
    "4": ThreeAndSevenColumn,
    "5": Text,
    "6": Link,
    "7": Image,
    "8": Video,
    "9": Map,
    "10": LinkBlock,
    "11": Quote,
    "12": TextSection,
    "13": Form,
    "14": Input,
    "15": TextArea,
    "16": Select,
    "17": Button,
    "18": Label,
    "19": CheckBox,
    "20": Radio,
    "21": CountDown,
    "22": Tabs,
    "23": CustomCode,
    "24": ToolTip,
    "25": Typed,
    "26": ImageSlider,
    "27": ToggleBox,
    "28": Calendar,
    "29": AlertBox,
    "30": IconBox,
    "31": SearchBox,
    "32": IconLibrary,
    "33": NavBarTypes,
    "34": LogoModal,
    "35": Footer,
    "36": Box,
    "37": Paragraph,
    "38": Heading,
    "39": TextBox,
    "40": Table,
    "41": VerticalLine,
    "42": TableHeader,
    "43": TableDetail,
    "44": TableFooter,
    "45": HorizontalLine,
    "46": RupeeSymbol,
    "47": Square,
    "48": ColumnOne,
    "49": ColumnOne
  };

  const componentNames = {
    "1": "ColumnOne",
    "2": "TwoColumn",
    "3": "ThreeColumn",
    "4": "ThreeAndSevenColumn",
    "5": "Text",
    "6": "Link",
    "7": "Image",
    "8": "Video",
    "9": "Map",
    "10": "LinkBlock",
    "11": "Quote",
    "12": "TextSection",
    "13": "Form",
    "14": "Input",
    "15": "TextArea",
    "16": "Select",
    "17": "Button",
    "18": "Label",
    "19": "CheckBox",
    "20": "Radio",
    "21": "CountDown",
    "22": "Tabs",
    "23": "CustonCode",
    "24": "ToolTip",
    "25": "Typed",
    "26": "ImageSlider",
    "27": "ToggleBox",
    "28": "Calendar",
    "29": "AlertBox",
    "30": "IconBox",
    "31": "SearchBox",
    "32": "IconLibrary",
    "33": "NavBarTypes",
    "34": "LogoBox",
    "35": "Footer",
    "36": "Box",
    "37": "Paragraph",
    "38": "Heading",
    "39": "TextBox",
    "40": "Table",
    "41": "Vertical Line",
    "42": "TableHeader",
    "43": "TableDetail",
    "44": "TableFooter",
    "45": "Horizontal Line",
    "46": "RupeeSymbol",
    "47": "Square",
    "48": "Header",
    "49": "Footer",
  };

  useEffect(() => {
    console.log("childComponentValue", childComponentValue);
  }, [childComponentValue]);

  const handleMouseEnter = (id) => {
    setHoveredComponentId(id);
  };

  const handleMouseLeave = () => {
    setHoveredComponentId(null);
  };

  const handleRemoveItem = (itemId) => {
    // Remove the item from childComponentValue
    setChildComponentValue(prevItems => {
      const updatedItems = prevItems.filter(item => item.id !== itemId);

      // Update droppedItems to reflect the removal
      setDroppedItems(prevState => {
        // Find the item in droppedItems where we need to update JSONChildComponents
        const itemIndex = prevState.findIndex(item => item.id === props.keyValueId);
        if (itemIndex === -1) return prevState;

        const updatedDroppedItems = [...prevState];
        const updatedJSONChildComponents = updatedDroppedItems[itemIndex].JSONChildComponents.filter(child => child.id !== itemId);

        updatedDroppedItems[itemIndex] = {
          ...updatedDroppedItems[itemIndex],
          JSONChildComponents: updatedJSONChildComponents,
        };

        return updatedDroppedItems;
      });
      // Remove the associated styles from stylesArray
      setStylesArray((prevStyles) => {
        return prevStyles.filter(styleObj => styleObj.id !== itemId);
      });
      return updatedItems;
    });
  };

  const dragstart = (e, id) => {
    // alert(id)
    e.dataTransfer.setData("text/plainChild", id.toString());
  };

  const dragover = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, dropId) => {
    // e.preventDefault(); // Uncomment if necessary to prevent default 
    const dragId = e.dataTransfer.getData("text/plainChild");
    const dragIndex = childComponentValue.findIndex(item => item.id === dragId);
    const dropIndex = childComponentValue.findIndex(item => item.id === dropId);

    console.log(dragId, dragIndex);
    console.log(dropId, dropIndex);

    if (dragIndex === -1 || dropIndex === -1) return;

    setChildComponentValue(prevItems => {
      // Swap the items
      const updatedItems = [...prevItems];
      const temp = updatedItems[dragIndex];
      updatedItems[dragIndex] = updatedItems[dropIndex];
      updatedItems[dropIndex] = temp;

      // Update droppedItems based on the new state of childComponentValue
      const ChildJsonAccToParentId = updatedItems.filter(item => item.parentJSONid === props.keyValueId); // Replace with actual keyValueId

      setDroppedItems(prevState => {
        const itemIndex = prevState.findIndex(item => item.id === props.keyValueId); // Replace with actual keyValueId
        if (itemIndex === -1) return prevState;

        const updatedDroppedItems = [...prevState];
        updatedDroppedItems[itemIndex] = {
          ...updatedDroppedItems[itemIndex],
          JSONChildComponents: ChildJsonAccToParentId,
        };
        return updatedDroppedItems;
      });

      return updatedItems;
    });
  };


  const generateHexId = (existingIds) => {
    let id;
    do {
      id = Math.random().toString(16).slice(2, 16).toLowerCase();
    } while (existingIds.has(id));
    return id;
  };

  const existingIds = new Set();


  const childcomponent = (e) => {
    e.preventDefault();
    const dragId = e.dataTransfer.getData("text/plainChild");
    console.log("Retrieved dragId:", dragId);

    const newId = generateHexId(existingIds);
    const itemIds = e.dataTransfer.getData("text").split(",");
    console.log(itemIds);

    const itemExists = childComponentValue.some(item => item.id === dragId);
    if (itemExists) {
      console.log(`Item with ID ${dragId} already exists.`);
      return;
    }

    const dropTargetId = e.currentTarget.getAttribute("data-component-id");
    const isChildComponent = dropTargetId && childComponentValue.some(item => item.id === dropTargetId);

    setChildComponentValue(prevItems => {
      let updatedItems = [...prevItems];

      const addItemRecursively = (items, targetId, newItem) => {
        return items.map(item => {
          if (item.id === targetId) {
            return {
              ...item,
              items: [...item.items, newItem],
              dropAreaId: e.timeStamp,
              parentJSONid: item.parentJSONid,
            };
          } else if (item.items.length > 0) {
            return {
              ...item,
              items: addItemRecursively(item.items, targetId, newItem),
            };
          }
          return item;
        });
      };

      if (isChildComponent) {
        const newItem = {
          id: newId,
          items: itemIds,
          dropAreaId: e.timeStamp,
          parentJSONid: dropTargetId,
        };
        updatedItems = addItemRecursively(updatedItems, dropTargetId, newItem);
      } else {
        const newItem = {
          id: newId,
          items: itemIds,
          dropAreaId: e.timeStamp,
          parentJSONid: props.keyValueId,
        };
        updatedItems = [...prevItems, newItem];
      }

      const ChildJsonAccToParentId = updatedItems.filter(item => item.parentJSONid === props.keyValueId);
      setDroppedItems(prevState => {
        const itemIndex = prevState.findIndex(item => item.id === props.keyValueId);
        if (itemIndex === -1) return prevState;

        const updatedDroppedItems = [...prevState];
        updatedDroppedItems[itemIndex] = {
          ...updatedDroppedItems[itemIndex],
          JSONChildComponents: ChildJsonAccToParentId,
        };
        return updatedDroppedItems;
      });

      return updatedItems;
    });
    setTimeout(() => fetchInnerHtmlOfDroppedComponent(props.keyValueId), 1000); // Replace with actual keyValueId
    e.stopPropagation();
  };
  const fetchInnerHtmlOfDroppedComponent = (componentId) => {
    console.log(droppedItems);
    const getRemainingData = (str) => {
      if (str.endsWith("CHILD")) {
        return str.replace("CHILD", "");
      } else if (str.endsWith("CHILD1")) {
        return str.replace("CHILD1", "");
      }
      return str;
    };

    var newID = getRemainingData(componentId)

    var ParentDivision = droppedItems.find(item => (item.id === newID))
    console.log(ParentDivision);
    console.log(componentId);
    console.log(newID);

    if (componentId.endsWith("CHILD") || componentId.endsWith("CHILD1")) {
      const componentElement = document.getElementById(newID.toString() + "innerHTML");
      console.log(componentElement);
      console.log(componentElement);

      if (componentElement) {
        // Get the inner HTML
        const innerHTML = componentElement.outerHTML;
        console.log(innerHTML);

        // Update the corresponding item with the inner HTML
        setDroppedItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.id === newID) {
              return { ...item, textContents: innerHTML };
            }
            return item;
          });
          // Update the template data with the new content
          setTemplateData(prevState => ({
            ...prevState,
            jsonStructure: updatedItems,
          }));
          return updatedItems;
        });
      }
    } else {
      const componentElement = document.getElementById(newID.toString());
      console.log(componentElement);
      console.log(componentElement);

      if (componentElement) {
        // Get the inner HTML
        const innerHTML = componentElement.outerHTML;
        console.log(innerHTML);

        // Update the corresponding item with the inner HTML
        setDroppedItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.id === componentId) {
              return { ...item, textContents: innerHTML };
            }
            return item;
          });
          // Update the template data with the new content
          setTemplateData(prevState => ({
            ...prevState,
            jsonStructure: updatedItems,
          }));

          return updatedItems;
        });
      }
    };
  }

  const childItemMap = droppedItems.find(item => item.id === props.keyValueId);
  const isHovered = hoveredComponentId === props.keyValueId;

  const handleComponentClick = (id) => {
    console.log(`Clicked component ID: ${id}`);
    setClickedItemId(id);
  };

  return (
    <div
      onDragStart={props.handleDrag}
      // onDrop={handleDrop}
      onDragOver={props.dragover}
      draggable="true"
      className="colomn-one-1-web-template"
      // id="column-one"
      style={{ width: "100%" }}
    >
      <div
        id={props.keyValueId}
        style={{
          // width: `${props.dimensions.widthPercent}%`, // Use percentage for width
          minHeight: props.dimensions.minHeight,
          padding: "5px 10px"
        }}
        // className="column-one-content-web-template resizers-web-template"
        // id="column-one-content"
        onMouseEnter={() => handleMouseEnter(props.keyValueId)}
        onMouseLeave={handleMouseLeave}
        onDrop={childcomponent}
        className={`column-one-content-web-template resizers-web-template component-web-template ${isHovered ? 'hover-border-web-template' : ''} `}
      >
        <div className={`resizer-web-template ${props.keyValueId} top-web-template `} onMouseDown={(e) => props.handleMouseDown(e, 'top')}></div>
        <div className={`resizer-web-template ${props.keyValueId} right-web-template `} onMouseDown={(e) => props.handleMouseDown(e, 'right')}></div>
        <div className={`resizer-web-template ${props.keyValueId} bottom-web-template `} onMouseDown={(e) => props.handleMouseDown(e, 'bottom')}></div>
        <div className={`resizer-web-template ${props.keyValueId} left-web-template `} onMouseDown={(e) => props.handleMouseDown(e, 'left')}></div>

        {childItemMap?.JSONChildComponents?.map(childValue => (
          <div key={childValue.id} className="dropped-item" style={{ width: "100%" }}>
            {childValue.items.map(itemId => {
              const Component = componentMap[itemId];
              if (Component) {
                const componentId = `component-${itemId}`;
                return (
                  <div

                    id={componentId}
                    style={{ width: "100%", height: "100%", position: "relative" }}
                    key={componentId}
                    data-component-id={childValue.id}
                    // style={{ opacity: "1" }}
                    draggable
                    onDragStart={(e) => dragstart(e, childValue.id)}
                    onDragOver={(e) => dragover(e)}
                    onDrop={(e) => onDrop(e, childValue.id)}
                    onClick={(e) => {
                      // alert("clicked Here")
                      e.stopPropagation();
                      // setClickedItemId(childValue.id);
                      setClickedItemName(componentNames[itemId]);
                    }}
                  >
                    {/* <p>{childValue.id}</p> */}
                    <Component
                      widthVa={props.widthV}
                      keyValueId={childValue.id}
                      onComponentClick={handleComponentClick}
                      handleRemoveItem={handleRemoveItem}
                    />
                    <button
                      className="component-delete-web-template"
                      id={`${childValue.id}delete`}
                      onClick={() => handleRemoveItem(childValue.id)}
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </div>
                );
              }
              return null;
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColumnOneMain;
