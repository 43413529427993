import { useContext, useEffect, useState } from "react";
import "./POSInvoice.css";
import KotHomeDelivery from './KotHomeDelivery'
import KotPickUp from './KotPickup'
import OrderApi from "../../API/APIServices/Order";
import TableApi from "../../API/APIServices/Table";
import TableGroupApi from "../../API/APIServices/TableGroup";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Table from "../../API/APIServices/Table";
import Loader from "../../Inventory/Props/Loader";
import Company from "../../API/APIServices/Company";
import OrderTakerBillPrint from "../OrderTaker/OrderTakerBillPrint";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import MyContext from "../../Inventory/MyContext";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const OrderView = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useState(() => {
        document.title = 'KOT Order List';
    }, []);


    const { UserDetails, maskMobileNo } = useContext(MyContext)




    //const [itemList] = useState([
    //    {
    //        id: 1,
    //        itemName: "Tandoori Momos",
    //        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq673pOk1Wllffzp4AEagD_qCN_Dng1qTnKg&usqp=CAU",
    //        price: 150.00,
    //    },
    //    {
    //        id: 2,
    //        itemName: "Garlic Bread",
    //        image: "https://w7.pngwing.com/pngs/55/450/png-transparent-garlic-bread-domino-s-pizza-falafel-benefits-of-raw-garlic-baked-goods-food-american-food.png",
    //        price: 100.00,
    //    },
    //    {
    //        id: 3,
    //        itemName: "Chilli Mushroom",
    //        image: "https://img-global.cpcdn.com/recipes/9291e0fe9073f60a/1200x630cq70/photo.jpg",
    //        price: 120.00,
    //    },
    //    {
    //        id: 4,
    //        itemName: "Hakka Noodles",
    //        image: "https://m.media-amazon.com/images/I/71dXe+aKzZL.jpg",
    //        price: 150.00,
    //    },
    //    {
    //        id: 5,
    //        itemName: "Veg Sandwich",
    //        image: "https://foodel.in/wp-content/uploads/2023/04/Layer-2-copy.png",
    //        price: 145.00,
    //    },
    //    {
    //        id: 6,
    //        itemName: "Spring Roll",
    //        image: "https://www.pngmart.com/files/16/Crispy-Spring-Rolls-Transparent-PNG.png",
    //        price: 80.00,
    //    },
    //    {
    //        id: 7,
    //        itemName: "Cheese Pasta",
    //        image: "https://e7.pngegg.com/pngimages/38/556/png-clipart-pasta-pasta-thumbnail.png",
    //        price: 180.00,
    //    },
    //    {
    //        id: 8,
    //        itemName: "Aloo Tikki Burger",
    //        image: "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
    //        price: 280.00,
    //    },
    //    {
    //        id: 9,
    //        itemName: "French Fries",
    //        image: "https://kirbiecravings.com/wp-content/uploads/2019/09/easy-french-fries-1.jpg",
    //        price: 99.00,
    //    },
    //])

    //const [isHovered, setIsHovered] = useState(false);
    //const [isHovered1, setIsHovered1] = useState(false);
    //const [isHovered2, setIsHovered2] = useState(false);
    //const [isHovered3, setIsHovered3] = useState(false);
    //const [isHovered4, setIsHovered4] = useState(false);
    //const [isHovered5, setIsHovered5] = useState(false);

    const applyStyles = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.backgroundColor = "#12234d";
        element.style.border = "1px solid #12234d";
        element.style.color = "white";
        element.style.padding = "10px";
        element.style.borderRadius = "10px";
        element.style.width = "33%";
        element.style.fontWeight = "600";
        //element.classList.add('black-btn');
    };

    const removeStyles = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.backgroundColor = "white";
        element.style.border = "2px solid #12234d";
        element.style.color = "#12234d";
        element.style.padding = "10px";
        element.style.borderRadius = "10px";
        element.style.width = "33%";
        element.style.fontWeight = "600";
    };
    // ***************** Get Order List *******************
    const [orderList, setOrderList] = useState([]);

    const getCurrentDate = async () => {
        const date = await CurrentDateAndTime.getCurrentDate();
        return date;
    };

    const fetchOrderData = async () => {
        setLoading(true)
        const date = await getCurrentDate();
        try {
            //const data = await OrderApi.getOrderList();
            const filterData = [
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: 751 // Table Order
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "Equal",
                    compareValue: "Confirm"
                },
                {
                    fieldName: "deliveryDate",
                    operatorName: "Equal",
                    compareValue: date,
                },
                {
                    fieldName: "deliveredStatus",
                    operatorName: "Equal",
                    compareValue: false
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Returned"
                },
            ]
            const list = await OrderApi.getOrderReportFilter(filterData);
            let data;
            if (list && list.length > 0) {
                data = list.slice().reverse();
            }
            //const data = await OrderApi.getOrderListByOrderNoFilter(filterData);
            //getOrderListByOrderNo(); //By Mamta 28-02-2024
            console.log(data);
            setOrderList(data);
            setLoading(false)
            //setDataToSend(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchOrderData();
    }, [])

    // ************** Table Group ************************
    const [tableGroup, setTableGroup] = useState();

    useEffect(() => {
        const getTableGroup = async () => {
            setLoading(true)
            try {
                const data = await TableGroupApi.getTableGroup();
                console.log(data);
                setTableGroup(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        getTableGroup();

        const getTable = async () => {
            setLoading(true)
            try {
                const data = await Table.getTable();
                console.log(data);
                setTableData(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        getTable();

    }, []);

    // ************** Table ************************
    const [tableData, setTableData] = useState();

    const onTableGroupSelection = async (e) => {
        const fieldName = "tableGroupId";
        const operatorName = "Equal";
        const compareValue = e.target.value;

        const data = await TableApi.getTableFromTableGroup(fieldName, operatorName, compareValue);
        setTableData(data);

        document.getElementById('TableNo').style.display = "block";
        document.getElementById('menu-pos').style.width = "80%";
    }

    const [tableGroupId, setTableGroupId] = useState(0);
    const [tableId, setTableId] = useState(0);

    const onFloorSelect = async (e) => {
        setLoading(true)
        const fieldName = "tableGroupId";
        const operatorName = "Equal";
        const compareValue = Number(e.target.value);
        setTableGroupId(compareValue)
        const data = await TableApi.getTableFromTableGroup(fieldName, operatorName, compareValue);
        setTableData(data);

        const date = await getCurrentDate();

        const filterData = [
            {
                fieldName: "tableGroupId",
                operatorName: "Equal",
                compareValue: Number(e.target.value)
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "Equal",
                compareValue: "Confirm"
            },
            {
                fieldName: "deliveredStatus",
                operatorName: "Equal",
                compareValue: false
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Returned"
            },
            {
                fieldName: "deliveryDate",
                operatorName: "Equal",
                compareValue: selectedDate || date,
            }
        ]
        const list = await OrderApi.getOrderReportFilter(filterData);
        const data1 = list.slice().reverse();
        setOrderList(data1);
        setLoading(false)
    }

    const onTableSelect = async (e) => {
        setLoading(true)
        const tableId = Number(e.target.value);
        setTableId(tableId);

        const date = await getCurrentDate();

        const filterData = [
            {
                fieldName: "tableId",
                operatorName: "Equal",
                compareValue: Number(e.target.value)
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "Equal",
                compareValue: "Confirm"
            },
            {
                fieldName: "deliveredStatus",
                operatorName: "Equal",
                compareValue: false
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Returned"
            },
            {
                fieldName: "deliveryDate",
                operatorName: "Equal",
                compareValue: selectedDate || date,
            }
        ]
        const list = await OrderApi.getOrderReportFilter(filterData);
        const data1 = list.slice().reverse();
        setOrderList(data1);
        setLoading(false)
    }

    const OrderWiseButtonClick = async (val) => {
        localStorage.setItem("KotServiceType", val);
        setLoading(true)
        const resetAllStyles = () => {
            const buttons = ['HomeDeliveryBtn', 'TableOrderBtn', 'PickUpBtn'];
            buttons.forEach((button) => {
                document.getElementById(button).classList.remove('black-btn');
                const buttonStyle = document.getElementById(button).style;
                buttonStyle.backgroundColor = "";
                buttonStyle.border = "";
                buttonStyle.color = "";
            });
        };
        resetAllStyles();

        const date = selectedDate || await getCurrentDate();
        if (val === 'HomeDelivery') {
            document.getElementById('tableorder').style.display = "none";
            document.getElementById('homedelivery').style.display = "block";
            document.getElementById('pickup').style.display = "none";
            applyStyles('HomeDeliveryBtn');
            removeStyles('TableOrderBtn');
            removeStyles('PickUpBtn');
            setServiceTypeId(752)

            const filterData = [
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: 752
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "Equal",
                    compareValue: "Confirm"
                },
                {
                    fieldName: "deliveryDate",
                    operatorName: "Equal",
                    compareValue: date,
                },
                {
                    fieldName: "deliveredStatus",
                    operatorName: "Equal",
                    compareValue: false
                },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Returned"
                // },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Cancel"
                // },
            ]
            const list = await OrderApi.getOrderReportFilter(filterData);
            let data;
            if (list && list.length > 0) {
                data = list.slice().reverse();
            }
            console.log("hd", data);
            setOrderList(data);
        }
        else if (val === 'TableOrder') {
            document.getElementById('tableorder').style.display = "block";
            document.getElementById('homedelivery').style.display = "none";
            document.getElementById('pickup').style.display = "none";
            applyStyles('TableOrderBtn');
            removeStyles('HomeDeliveryBtn');
            removeStyles('PickUpBtn');
            setServiceTypeId(751)
            const filterData = [
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: 751
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "Equal",
                    compareValue: "Confirm"
                },
                {
                    fieldName: "deliveryDate",
                    operatorName: "Equal",
                    compareValue: date,
                },
                {
                    fieldName: "deliveredStatus",
                    operatorName: "Equal",
                    compareValue: false
                },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Returned"
                // },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Cancel"
                // },
            ]
            const list = await OrderApi.getOrderReportFilter(filterData);
            let data;
            if (list && list.length > 0) {
                data = list.slice().reverse();
            }
            console.log("to", data);
            setOrderList(data);
        }
        else if (val === 'PickUp') {
            document.getElementById('tableorder').style.display = "none";
            document.getElementById('homedelivery').style.display = "none";
            document.getElementById('pickup').style.display = "block";
            applyStyles('PickUpBtn');
            removeStyles('TableOrderBtn');
            removeStyles('HomeDeliveryBtn');
            setServiceTypeId(753)
            const filterData = [
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: 753
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "Equal",
                    compareValue: "Confirm"
                },
                {
                    fieldName: "deliveredStatus",
                    operatorName: "Equal",
                    compareValue: false
                },
                {
                    fieldName: "deliveryDate",
                    operatorName: "Equal",
                    compareValue: date,
                },
            ]
            const list = await OrderApi.getOrderReportFilter(filterData);
            let data;
            if (list && list.length > 0) {
                data = list.slice().reverse();
            }
            console.log("pu", data);
            setOrderList(data);
        }
        setLoading(false);
    };

    const [progress] = useState(70);

    const [itemList, setItemList] = useState();
    const [tableName, setTableName] = useState();
    const [openViewModal, setOpenViewModal] = useState(false);

    const handleViewBtn = async (ord1Id, tableName) => {
        const orderData = await OrderApi.getOrderItemWiseList(ord1Id);
        const data = orderData.filter(item => item.cancelApprovalStatus != "Approve")
        setItemList(data);
        setTableName(tableName);
        setOpenViewModal(true)
        setOrd1Id(ord1Id);

    }
    const closeViewBtn = () => {
        setOpenViewModal(false)
        setOrd1Id("");
    }

    function formatDayOfMonth(day) {
        if (day >= 11 && day <= 13) {
            return day + 'th';
        }
        switch (day % 10) {
            case 1: return day + 'st';
            case 2: return day + 'nd';
            case 3: return day + 'rd';
            default: return day + 'th';
        }
    }

    const [currentDateTime, setCurrentDateTime] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const date = await getCurrentDateTime1();
            setCurrentDateTime(date);
        }
        fetchData();
    }, [])

    async function getCurrentDateTime1() {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        const isoString = await CurrentDateAndTime.getCurrentDateTime();
        const now = new Date(isoString);

        const dayOfWeek = days[now.getDay()];
        const month = months[now.getMonth()];
        const dayOfMonth = now.getDate();
        const year = now.getFullYear();
        let hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();

        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (0 hours)

        const formattedDayOfMonth = formatDayOfMonth(dayOfMonth);

        return `${dayOfWeek} ${formattedDayOfMonth} ${month} ${year} - ${hours}:${formatTime(minutes)} ${period}`;
    }

    function formatTime(time) {
        return time < 10 ? `0${time}` : time;
    }

    function formatTime(time) {
        return time < 10 ? `0${time}` : time;
    }

    const [kotItemModal, setKotItemModal] = useState(false);
    const [statusList, setStatusList] = useState();
    const [ord1Id, setOrd1Id] = useState();

    const fetchStatusList = async (ord1Id) => {
        setLoading(true);
        const orderData = await OrderApi.getOrderItemWiseList(ord1Id);
        const data = orderData.filter(item => item.cancelApprovalStatus != "Approve")
        setItemList(data);
        const statusData = [];
        for (let i = 0; i < data.length; i++) {
            const fetchedData = await OrderApi.getItemWiseStatusList(ord1Id, data[i].ord2Id);
            statusData.push(fetchedData);
        }
        setStatusList(statusData);
        console.log("statusData", statusData)
        setLoading(false);
    }

    const openKotItemModal = async (ord1Id, tableName) => {
        //alert(ord1Id, +"--" + tableName);
        setTableName(tableName);
        setKotItemModal(true);
        setOrd1Id(ord1Id);

        await fetchStatusList(ord1Id)
    }
    const closeKotItemModal = () => {
        setOrd1Id("");
        setKotItemModal(false);
    }

    const [serviceTypeId, setServiceTypeId] = useState(751);

    useEffect(() => {
        const data = localStorage.getItem("KotServiceType");
        OrderWiseButtonClick(data ? data : 'TableOrder');
    }, [])

    async function getCurrentTime() {
        const time = await CurrentDateAndTime.getCurrentTime();
        return time;
    }

    const UpdateBtnClick = async (ord1Id, ord2Id, kotStatus, index) => {
        const date = await CurrentDateAndTime.getCurrentDateTime();
        // const date = new Date(isoString);

        setLoading(true)
        let cookStatusValue;
        if (itemList[index].kotStatus === "Transferred")
            cookStatusValue = "Queue";
        else if (itemList[index].kotStatus === "Processing")
            cookStatusValue = "Process";
        else if (itemList[index].kotStatus === "Ready")
            cookStatusValue = "Complete";
        else
            cookStatusValue = itemList[index].kotStatus;

        const data = [
            {
                operationType: 0,
                path: "KotStatus",
                op: "Add",
                from: "string",
                value: itemList[index].kotStatus
            },
            {
                operationType: 0,
                path: "CookStatus",
                op: "Add",
                from: "string",
                value: cookStatusValue
            }
        ]
        await OrderApi.updateItemPartially(data, ord2Id);
        // *****************************************************
        const insertData = [
            {
                ord1Id: Number(ord1Id),
                ord2Id: Number(ord2Id),
                orderStatus: kotStatus,
                orderDateTime: date,
            }
        ]
        console.log(insertData);
        await OrderApi.insertItemStatusForKotStatusList(insertData);

        // *********** If All Items are Delivered ----> update KOTDispatch Time *********
        if (kotStatus === "Delivered") {
            const filteredData = [
                // {
                //     fieldName: "ord1Id",
                //     operatorName: "Equal",
                //     compareValue: ord1Id,
                // },
                {
                    fieldName: "o2KotStatus",
                    operatorName: "NotEqual",
                    compareValue: "Delivered",
                },
            ]
            const data = await OrderApi.getOrderListByOrd1IdWithFilter(filteredData, ord1Id);
            if (data.length == 0) {
                const time = await getCurrentTime();
                const updatedValues = [
                    {
                        operationType: 0,
                        path: "KOTDispatchTime",
                        op: "Add",
                        from: "string",
                        value: time,
                    }
                ]
                await OrderApi.updateOrderPartially(updatedValues, ord1Id);
            }
        }

        fetchStatusList(ord1Id);
        setLoading(false)
        alert("Updated")
    }

    const [orderID, setOrderId] = useState([]);
    const [data, setData] = useState([]);
    const [insertStatus, setInsertStatus] = useState([]);

    const handleInputChange = (event, ord1Id, ord2Id, index) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setItemList(prevItemList => {
            const updatedItemList = [...prevItemList];
            updatedItemList[index] = { ...updatedItemList[index], [name]: value };
            return updatedItemList;
        });
        console.log(itemList);

        let cookStatusValue;
        if (value === "Transferred")
            cookStatusValue = "Queue";
        else if (value === "Processing")
            cookStatusValue = "Process";
        else if (value === "Ready")
            cookStatusValue = "Complete";
        else
            cookStatusValue = value;

        const newObj = [{
            operationType: 0,
            path: "KotStatus",
            op: "Add",
            from: "string",
            value: value
        },
        {
            operationType: 0,
            path: "CookStatus",
            op: "Add",
            from: "string",
            value: cookStatusValue
        }]

        const orderIDcreation = {
            ord2Id: ord2Id
        }

        setData(prevData => [...prevData, newObj]);
        setOrderId(prevData => [...prevData, orderIDcreation]);

        // *************** Insert Status ****************
        setInsertStatus(prevValues => {
            const existingIndex = prevValues.findIndex(item => item.ord2Id === ord2Id);
            if (existingIndex !== -1) {
                // Update existing status
                const newStatuses = [...prevValues];
                newStatuses[existingIndex] = {
                    ...newStatuses[existingIndex],
                    orderStatus: value,
                    orderDateTime: ""
                };
                return newStatuses;
            } else {
                // Add new status
                return [
                    ...prevValues,
                    {
                        ord1Id: ord1Id,
                        ord2Id: ord2Id,
                        orderStatus: value,
                        orderDateTime: "",
                    }
                ];
            }
        });
    }

    const handlePrint = (orderNo, itemName, preferenceNo, qty, sku, ord2Id) => {
        navigate("/KotPrintPage", {
            state: {
                orderNo,
                itemName,
                preferenceNo,
                qty,
                sku,
                ord2Id
            }
        });
    }

    const [showBillPrint, setShowBillPrint] = useState(false);

    const handleBillPrintButton = () => {
        // const id = ord1Id;
        // navigate("/OrderTakerBillPrint", {
        //     state: {
        //         id
        //     }
        // });
        setShowBillPrint(true);
    }


    const closeBillPrint = () => {
        setShowBillPrint(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            const date = await getCurrentDate();
            setSelectedDate(date)
        }
        fetchData();
    }, [])

    const [selectedDate, setSelectedDate] = useState();

    const handleSelectedDateChange = async (event) => {
        setSelectedDate(event.target.value);
        setLoading(true)
        const filterData = [
            {
                fieldName: "orderDate",
                operatorName: "Equal",
                compareValue: event.target.value
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "Equal",
                compareValue: "Confirm"
            },
            {
                fieldName: "deliveredStatus",
                operatorName: "Equal",
                compareValue: false
            }
        ]
        const list = await OrderApi.getOrderReportFilter(filterData);
        let data1;
        if (list && list.length > 0) {
            data1 = list.slice().reverse();
        }
        setOrderList(data1);
        setLoading(false)
    };

    const handleSearchBtn = async () => {
        const filterData = [
            {
                fieldName: "orderDate",
                operatorName: "Equal",
                compareValue: selectedDate
            },
            {
                fieldName: "serviceTypeId",
                operatorName: "Equal",
                compareValue: serviceTypeId
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "Equal",
                compareValue: "Confirm"
            },
            {
                fieldName: "deliveredStatus",
                operatorName: "Equal",
                compareValue: false
            }
        ];

        if (tableGroupId != 0) {
            filterData.push({
                fieldName: "tableGroupId",
                operatorName: "Equal",
                compareValue: tableGroupId
            });
        }
        if (tableId != 0) {
            filterData.push({
                fieldName: "tableId",
                operatorName: "Equal",
                compareValue: tableId
            });
        }

        try {
            const data = await OrderApi.getOrderListByOrderNoFilter(filterData);
            setOrderList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        if (!twentyFourHourTime) {
            return "";
        }

        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        if (isNaN(hours) || isNaN(minutes)) {
            return "";
        }

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }

        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }

        return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    // **************** Update Status ***************

    const KotStatus = async () => {
        setLoading(true)
        const currentDate = await CurrentDateAndTime.getCurrentDateTime();
        // const currentDate = new Date(isoString);

        await OrderApi.updateItemPartiallyMultiple(data, orderID);

        for (var i = 0; i < insertStatus.length; i++) {
            const insertData = [
                {
                    ord1Id: Number(insertStatus[i].ord1Id),
                    ord2Id: Number(insertStatus[i].ord2Id),
                    orderStatus: insertStatus[i].orderStatus,
                    orderDateTime: currentDate,
                }
            ]
            console.log(insertData);
            await OrderApi.insertItemStatusForKotStatusList(insertData);

            // *********** If All Items are Delivered ----> update KOTDispatch Time *********
            if (insertStatus[i].orderStatus === "Delivered") {
                const filteredData = [
                    // {
                    //     fieldName: "ord1Id",
                    //     operatorName: "Equal",
                    //     compareValue: ord1Id,
                    // },
                    {
                        fieldName: "o2KotStatus",
                        operatorName: "NotEqual",
                        compareValue: "Delivered",
                    },
                ]
                const time = await getCurrentTime();
                const data = await OrderApi.getOrderListByOrd1IdWithFilter(filteredData, ord1Id);
                if (data.length == 0) {
                    const updatedValues = [
                        {
                            operationType: 0,
                            path: "KOTDispatchTime",
                            op: "Add",
                            from: "string",
                            value: time,
                        }
                    ]
                    await OrderApi.updateOrderPartially(updatedValues, ord1Id);
                }
            }
        }
        handleRefreshBtn();
        setInsertStatus([]);
        setLoading(false);
        alert("Updated");
    }

    const [showRunningOrderColor, setRunningOrderColor] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                const data = await Company.GetCompanyById(loginUser.compId)
                setRunningOrderColor(data.isAllowDiffColorRunningOrder)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }
    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    const handleRefreshBtn = async () => {
        setLoading(true);
        // alert(serviceTypeId); 
        let filterData = [
            {
                fieldName: "overallOrderStatus",
                operatorName: "Equal",
                compareValue: "Confirm"
            },
            {
                fieldName: "deliveredStatus",
                operatorName: "Equal",
                compareValue: false
            },
            {
                fieldName: "deliveryDate",
                operatorName: "Equal",
                compareValue: selectedDate,
            },
            {
                fieldName: "serviceTypeId",
                operatorName: "Equal",
                compareValue: serviceTypeId,
            }
        ]

        if (serviceTypeId == 751) {

            if (tableGroupId != 0) {
                filterData.push({
                    fieldName: "tableGroupId",
                    operatorName: "Equal",
                    compareValue: tableGroupId
                });
            }
            if (tableId != 0) {
                filterData.push({
                    fieldName: "tableId",
                    operatorName: "Equal",
                    compareValue: tableId
                });
            }

            const list = await OrderApi.getOrderReportFilter(filterData);
            const data1 = list.slice().reverse();
            setOrderList(data1);
            setLoading(false)
            //OrderWiseButtonClick("TableOrder");
        }
        else if (serviceTypeId == 752) {
            const list = await OrderApi.getOrderReportFilter(filterData);
            const data1 = list.slice().reverse();
            setOrderList(data1);
            setLoading(false)
            // OrderWiseButtonClick("HomeDelivery");
        }
        else if (serviceTypeId == 753) {
            const list = await OrderApi.getOrderReportFilter(filterData);
            const data1 = list.slice().reverse();
            setOrderList(data1);
            setLoading(false)
            // OrderWiseButtonClick("PickUp");
        }
        else {
            setLoading(false);
        }
    }

    const imageUrl = `${WebApiUrl}/api/Order/TripImage?imageId=`;

    function convertTimeToPeriod(time24) {
        const [hours, minutes, seconds] = time24.split(':').map(Number);

        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12;
        const time12 = `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;

        return time12;
    }

    const [firmName, setFirmName] = useState();
    const [userName, setUserName] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const arrayString = localStorage.getItem('loginUser');
            const arrayToStore = JSON.parse(arrayString);
            setUserName(arrayToStore.userName);
            const data = await Company.GetCompanyById(arrayToStore.compId);
            setFirmName(data.name);
        };
        fetchData();
    }, []);

    return (
        <div>
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">KOT ORDER LIST</h2>
                </div>
                <div className="loggedInDataDiv" >
                    <label className="loggedInDataLabel">Hi,{userName}</label>
                    <label className="loggedInDataLabel">{firmName}</label>
                </div>
            </div>
            <div className="scroll-container" style={{ height: "99vh", padding: "10px" }}>
                <div className="mt-2">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <label className="fw-600 mt-1" style={{ fontSize: "18px", width: "20%", textDecoration: "underline" }}>Order List</label>
                            <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                <select className="modify_im_input mode_limit mode_category" onChange={onFloorSelect} style={{}}>
                                    <option value="">Select Table Group</option>
                                    {(() => {
                                        if (tableGroup !== null && Array.isArray(tableGroup)) {
                                            return tableGroup.map((data) => (
                                                <option key={data.id} value={data.id}>
                                                    {data.name}
                                                </option>
                                            ))
                                        }
                                    })()}
                                </select>
                            </div>
                            <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                <select className="modify_im_input mode_limit mode_category" onChange={onTableSelect} style={{}}>
                                    <option value="">Select Table No</option>
                                    {(() => {
                                        if (tableData !== null && Array.isArray(tableData)) {
                                            return tableData.map((data) => (
                                                <option key={data.id} value={data.id}>
                                                    {data.tableName}
                                                </option>
                                            ))
                                        }
                                    })()}
                                </select>
                            </div>
                            <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                <input className="form-control inputEffect modify_im_input" type="date" id="Date" required
                                    onChange={handleSelectedDateChange} value={selectedDate} />
                                <label id="input1" className="labelEffect" style={{}}>Date</label>
                            </div>
                            <div className="searchIcon" onClick={handleSearchBtn} style={{}}>
                                <i class="fa-solid fa-magnifying-glass mt-1" style={{ color: "black", cursor: "pointer" }}></i>
                            </div>
                        </div>
                        <button onClick={handleRefreshBtn} style={{ padding: "5px", borderRadius: "5px", margin: "8px", backgroundColor: "lavender", border: "1px solid #bfbfea" }}>Refresh</button>
                        <div style={{ display: "flex", justifyContent: "flex-end", width: "50%" }}>
                            <button id="TableOrderBtn" className="black-btn" style={{}} onClick={() => OrderWiseButtonClick('TableOrder')}>Table&nbsp;Order</button>
                            <button id="HomeDeliveryBtn" className="black-btn" style={{}} onClick={() => OrderWiseButtonClick('HomeDelivery')}>Home&nbsp;Delivery</button>
                            <button id="PickUpBtn" className="black-btn" style={{}} onClick={() => OrderWiseButtonClick('PickUp')}>Pick&nbsp;Up</button>
                        </div>
                    </div>
                    {/****************** Table Order ****************/}
                    <div id="tableorder" style={{ display: "block", padding: "0px", marginRight: "15px" }}>
                        <table className="tableStyle mt-4">
                            <thead>
                                <tr>
                                    <th className="thStyle">Select</th>
                                    <th className="thStyle">Order No.</th>
                                    <th className="thStyle">Table No.</th>
                                    <th className="thStyle">Mobile&nbsp;No.</th>
                                    <th className="thStyle">Order Date & Time</th>
                                    <th className="thStyle">Progress Status</th>
                                    <th className="thStyle">KOT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    if (orderList !== null && Array.isArray(orderList)) {
                                        return orderList.filter(data => (data.deliveredStatus == false && (data.hasRunningOrder && data.hasRunningOrder == true) || data.overallOrderStatus === "Confirm"))
                                            // .slice().reverse()
                                            .map((data, index) => {
                                                let backgroundStyle = { backgroundColor: "white" };
                                                if (data.runningOrderCounter && data.runningOrderCounter > 0) {
                                                    if (showRunningOrderColor) {
                                                        backgroundStyle.backgroundColor = "rgba(255, 192, 203, 0.64)"; // Or "rgb(234, 163, 163)";
                                                    }
                                                }
                                                return (
                                                    <tr className="tableHover" style={{ ...backgroundStyle, marginTop: "0px", cursor: "pointer" }}>  {/*display: width == 100 ? "none" : "block", */}
                                                        <td className="tdStyle" style={{ textAlign: "center", }}>
                                                            <button onClick={() => openKotItemModal(data.ord1Id, data.tableName)} style={{ padding: "5px 12px", backgroundColor: "rgba(0, 0, 0, 0.32)", color: "white" }}>{index + 1}</button></td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{data.orderNo}</td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{data.tableName}</td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{UserDetails.canLst3DgtNo ? maskMobileNo(data.accMobileNo) : data.accMobileNo}</td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{moment(data.orderDate).format('DD-MM-YYYY')} -- {convertTo12HourFormat(data.orderTime)}</td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">
                                                            <div style={{ width: '100%', backgroundColor: 'rgb(173, 173, 173)', height: '20px' }}>
                                                                <div
                                                                    style={{
                                                                        width: `${((orderList[index].countQueue * 100) / (orderList[index].countItems * 4)) +
                                                                            ((orderList[index].countProcess * 100) / (orderList[index].countItems * 2)) +
                                                                            ((orderList[index].countComplete * 100) / (orderList[index].countItems * 1.25)) +
                                                                            ((orderList[index].countDelivered * 100) / (orderList[index].countItems * 1))}%`,
                                                                        backgroundColor: "rgb(16, 179, 16)",//'#00b900',
                                                                        height: '100%',
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}
                                                                ><h6 style={{ fontWeight: "bold", color: "white", marginLeft: "30px" }}>
                                                                        {Math.floor(
                                                                            ((orderList[index].countQueue * 100) / (orderList[index].countItems * 4)) +
                                                                            ((orderList[index].countProcess * 100) / (orderList[index].countItems * 2)) +
                                                                            ((orderList[index].countComplete * 100) / (orderList[index].countItems * 1.25)) +
                                                                            ((orderList[index].countDelivered * 100) / (orderList[index].countItems * 1))
                                                                        )}%
                                                                        {/* {width} % */}
                                                                    </h6></div>
                                                            </div>
                                                        </td>
                                                        <td className="tdStyle" style={{}}>
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                <button className="viewBlueBtn" onClick={() => handleViewBtn(data.ord1Id, data.tableName)}>View</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            });
                                    }
                                })()}
                            </tbody>
                        </table>
                    </div>
                    {/****************** Home Delivery **************/}
                    <div id="homedelivery" style={{ display: "none" }}>
                        <KotHomeDelivery orderList={orderList} openKotItemModal={(ord1Id, tableName) => openKotItemModal(ord1Id, tableName)} handleViewBtn={(ord1Id, tableName) => handleViewBtn(ord1Id, tableName)} />
                    </div>
                    {/****************** Pickup *********************/}
                    <div id="pickup" style={{ display: "none" }}>
                        <KotPickUp orderList={orderList} openKotItemModal={(ord1Id, tableName) => openKotItemModal(ord1Id, tableName)} handleViewBtn={(ord1Id, tableName) => handleViewBtn(ord1Id, tableName)} />
                    </div>
                </div>
            </div>
            {/************************** Kot View Modal ***************/}
            {openViewModal && (
                <div className="modal-back">
                    <div className="info-modal" style={{ maxHeight: "90%", }}>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                            <label className="fw-600" style={{ fontSize: "18px" }}>YES-BM</label>
                            <i className="fa-solid fa-xmark xmark" onClick={closeViewBtn}></i>
                        </div>
                        <hr />
                        <div className="">
                            <div className="flex-start">
                                <div style={{ marginLeft: "10px" }}>
                                    <div>
                                        <div className="space-between" style={{ width: "100%" }}>
                                            <label>Order No : {itemList && itemList.length > 0 ? itemList[0].orderNo : ''}</label>
                                            <label style={{ display: serviceTypeId == 751 ? "block" : "none" }}>Table Name : {tableName && tableName.length > 0 ? tableName : ''}</label>
                                        </div>
                                        <div className="mt-3">
                                            {itemList && itemList.length > 0 && itemList.map((data, index) => (
                                                <div key={index}>
                                                    <label>{index + 1}. {data.d2ItemName}  (Qty: {data.qty})</label><br />
                                                </div>
                                            ))}
                                            <div className="mt-3">
                                                <label>Print Time - {currentDateTime}</label><br />
                                                <label>Remarks: </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                            <button className="greenBtn" style={{ width: "30%" }} onClick={() => handleBillPrintButton()}>Print</button>
                            <button className="redBtn" style={{ width: "30%" }} onClick={closeViewBtn}>Close</button>
                        </div>
                    </div>
                </div>
            )}
            {/************************** Kot Item Detail Modal ***************/}
            {kotItemModal && (
                <div className="modal-back">
                    <div className="info-modal" style={{ maxHeight: "90%", height: "auto", overflow: "scroll", width: "80%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                            <label className="fw-600" style={{ fontSize: "18px" }}>KOT Item Detail</label>
                            <i className="fa-solid fa-xmark xmark" onClick={closeKotItemModal}></i>
                        </div>
                        <hr />
                        <div className="">
                            <div className="flex-start">
                                <div style={{ marginLeft: "10px" }}>
                                    <div>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <label>Order No : {itemList && itemList.length > 0 ? itemList[0].orderNo : ''}</label><br />
                                            <label>Order Date : {itemList && itemList.length > 0 ? moment(itemList[0].orderDate).format('DD-MM-YYYY') : ''}</label><br />
                                            <label style={{ display: serviceTypeId == 751 ? "block" : "none" }}>Table Name : {tableName && tableName.length > 0 ? tableName : ''}</label>
                                        </div>
                                        <div className="mt-3">
                                            <div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="th">S No</th>
                                                            <th className="th">Item Name</th>
                                                            <th className="th">Amount</th>
                                                            <th className="th">Status</th>
                                                            {/* <th className="th">Update</th> */}
                                                            <th className="th">Print</th>
                                                            <th className="th">Status List</th>
                                                            <th className="th">Image</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {itemList && itemList.length > 0 && itemList
                                                            // .filter(item => item.kotStatus !== "Pending" && item.kotStatus !== "Delivered")
                                                            .map((data, index) => {
                                                                let backgroundColor = "white";
                                                                if (data.itemRunningQty > 0) {
                                                                    backgroundColor = 'rgb(249, 214, 214, 0.6)'
                                                                }
                                                                return (
                                                                    <tr key={index} style={{ backgroundColor: backgroundColor, cursor: "pointer" }}> {/* display: data.deliveredStatus == true ? "none" : "block" */}
                                                                        <td className="td">{index + 1}.</td>
                                                                        <td className="td">
                                                                            <div>
                                                                                <label>{data.d2ItemName} - Qty:{data.qty}</label><br />
                                                                                <label>Delivery Pref/Note - <span style={{ color: "blue", fontWeight: "600" }}>{data.preferenceNo}</span></label><br />
                                                                                {(data.itemSuggestion === "" || data.itemSuggestion === null || data.itemSuggestion === undefined) ? (
                                                                                    <label></label>
                                                                                ) : (
                                                                                    <>
                                                                                        <label>Item Suggestion - <span style={{ color: "darkgreen", fontWeight: "600" }}>{data.itemSuggestion}</span></label><br />
                                                                                    </>
                                                                                )}
                                                                                {(data.itemConfirmedByName === "" || data.itemConfirmedByName === null || data.itemConfirmedByName === undefined) ? (
                                                                                    <label></label>
                                                                                ) : (
                                                                                    <label>Item Confirm By - <span style={{ color: "darkred", fontWeight: "600" }}>{data.itemConfirmedByName} <span style={{ color: "black", fontWeight: "500" }}>on</span> {moment.utc(data.itemConfirmedOn1).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</span></label>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                        <td className="td">₹&nbsp;{data.qty * data.rate}  </td>
                                                                        <td className="td">
                                                                            <select value={data.kotStatus} name="kotStatus" onChange={(e) => handleInputChange(e, data.ord1Id, data.ord2Id, index)}>
                                                                                <option>Select</option>
                                                                                <option value="Received">Kot Received</option>
                                                                                <option value="Transferred">Kot Transferred</option>
                                                                                <option value="Processing">Kot Processing</option>
                                                                                <option value="Ready">Kot Ready</option>
                                                                                {/* <option value="Pickup">Kot Pickup</option> */}
                                                                                <option value="Delivered">Kot Delivered</option>
                                                                            </select>
                                                                        </td>
                                                                        {/* <td className="td" onClick={() => UpdateBtnClick(data.ord1Id, data.ord2Id, data.kotStatus, index)}><button>Update</button></td> */}
                                                                        <td className="td" onClick={() => handlePrint(data.orderNo, data.d2ItemName, data.preferenceNo, data.qty, data.sku, data.ord2Id)}><button>Print</button></td>
                                                                        <td className="td">
                                                                            {statusList && statusList.map((innerList, outerIndex) => (
                                                                                innerList.filter(statusData => statusData.ord1Id === data.ord1Id && statusData.ord2Id === data.ord2Id).map((statusData, innerIndex) => (
                                                                                    <div key={`${outerIndex}-${innerIndex}`}>
                                                                                        <label><span style={{ color: "black", fontSize: "20px" }}>•</span> {statusData.orderStatus} by {statusData.stausCreatedBy} on {moment.utc(statusData.statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label><br />
                                                                                    </div>
                                                                                ))
                                                                            ))}
                                                                        </td>
                                                                        <td className="td">
                                                                            {data.ord2ImageId != 0 && data.ord2ImageId != null && data.ord2ImageId != undefined && (
                                                                                <img className="hover-enlarge-image" src={`${imageUrl}${data.ord2ImageId}`}></img>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="mt-3">
                                                <label><span style={{ fontWeight: "600" }}>Print Time -</span> {currentDateTime}</label><br />
                                                {/* <label><span style={{ fontWeight: "600" }}>Delivery Time Slot - </span>{(itemList && timeFormat(itemList[0].deliveryTime))}</label><br /> */}
                                                <label><span style={{ fontWeight: "600" }}>Delivery Time Slot - </span>{(itemList && (itemList[0].timeSlotName))}</label><br />
                                                <label><span style={{ fontWeight: "600" }}>KDTT - </span>{(itemList && convertTimeToPeriod(itemList[0].kotDispatchTargetTime))}</label><br />
                                                <label><span style={{ fontWeight: "600" }}>Remarks: </span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                            <button className="greenBtn" style={{ width: "10%" }} onClick={() => handleBillPrintButton()}>Receipt</button>
                            <button className="greenBtn" style={{ width: "10%" }} onClick={() => KotStatus()}>Update</button>
                            {/* <button className="greenBtn" style={{ width: "10%" }} >Update</button> */}
                            <button className="redBtn" style={{ width: "10%" }} onClick={closeKotItemModal} >Close</button>
                        </div>
                    </div>
                </div>
            )}
            {loading && <Loader />}
            {/******************************* Bill Modal *************************/}
            {showBillPrint && (
                <div>
                    <div className="modal-back" onClick={closeBillPrint}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                <OrderTakerBillPrint id={ord1Id} closePrintModal={closeBillPrint} backBtn={"modal"} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrderView;
