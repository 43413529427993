import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ColumnOneMain from './ColumnOneMain';
import MyContext from '../../../Inventory/MyContext';
// import './ColumnOne.css'; // Assuming you have styles for .resizer classes here

const ColumnOne = ({
  keyValueId, handleDrag, handleDrop, dragover, initialWidthPercent = 100, initialHeight = 100, onClick
}) => {
  const { TemplateData, setTemplateData, droppedItems, setDroppedItems, setStylesArray,
    handleStyleChange, setClickedItemId, updateStyle, stylesArray } = useContext(MyContext);

  const { hoveredComponentId, setHoveredComponentId } = useContext(MyContext);
  const [dimensions, setDimensions] = useState({
    widthPercent: initialWidthPercent,
    minHeight: initialHeight,
    top: 0,
    left: 0,
  });

  const [activeId, setActiveId] = useState('');
  const hasInitialized = useRef(false); // Ref to track initialization

  // Function to parse textContents and update dimensions
  const parseTextContents = (textContents) => {
    const regex = /Width:\s*(\d+)%.*Height:\s*(\d+)px.*Top:\s*(\d+)px.*Left:\s*(\d+)px/;
    const match = textContents.match(regex);
    if (match) {
      return {
        widthPercent: parseFloat(match[1]),
        height: parseFloat(match[2]),
        top: parseFloat(match[3]),
        left: parseFloat(match[4]),
      };
    }
    return null;
  };

  // Effect to initialize dimensions based on droppedItems
  useEffect(() => {
    if (!hasInitialized.current) {
      const item = droppedItems.find(item => item.id === keyValueId);
      if (item) {
        if (item.textContents) {
          const parsedDimensions = parseTextContents(item.textContents);
          if (parsedDimensions) {
            setDimensions(parsedDimensions);
          }
        } else {
          setDimensions({
            widthPercent: item.widthPercent || dimensions.widthPercent,
            minHeight: item.height || dimensions.minHeight,
            top: item.top || dimensions.top,
            left: item.left || dimensions.left,
          });
        }
      }
      hasInitialized.current = true; // Set the ref to true after initialization
    }
  }, [keyValueId, droppedItems]);

  // Function to update droppedItems with new dimensions as textContents
  const updateDroppedItemsWithDimensions = (newWidthPercent, newHeight) => {
    const textContents = `Width: ${newWidthPercent}%, Height: ${newHeight}px, Top: ${dimensions.top}px, Left: ${dimensions.left}px`;
    setDroppedItems(prevItems => prevItems.map(item =>
      item.id === keyValueId ? { ...item, textContents } : item
    ));
  };

  // Function to handle mouse down events for resizing
  const handleMouseDown = useCallback((e, direction) => {
    e.preventDefault();

    const originalWidthPercent = dimensions.widthPercent;
    const originalHeight = dimensions.minHeight;
    const originalMouseX = e.pageX;
    const originalMouseY = e.pageY;
    const container = document.getElementById(keyValueId);

    if (!container) return;

    const containerWidth = container.offsetWidth; // Get container width in pixels

    const maxWidth = containerWidth; // Maximum width in pixels (100% of container width)
    const minWidthPercent = 5; // Minimum width percentage
    const minHeight = 100; // Minimum height in pixels

    const onMouseMove = (e) => {
      let newWidthPercent = originalWidthPercent;
      let newHeight = originalHeight;

      if (direction === 'right') {
        const deltaX = e.pageX - originalMouseX;
        let newWidth = (originalWidthPercent / 100 * containerWidth) + deltaX;

        // Ensure newWidth doesn't exceed containerWidth
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }

        newWidthPercent = (newWidth / containerWidth) * 100;
      } else if (direction === 'left') {
        const deltaX = originalMouseX - e.pageX;
        let newWidth = (originalWidthPercent / 100 * containerWidth) - deltaX;

        // Ensure newWidth doesn't go below minimum width
        const minWidthInPixels = minWidthPercent / 100 * containerWidth;
        if (newWidth < minWidthInPixels) {
          newWidth = minWidthInPixels;
        }

        if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }

        newWidthPercent = (newWidth / containerWidth) * 100;
      } else if (direction === 'top') {
        newHeight = originalHeight - (e.pageY - originalMouseY);
        if (newHeight < minHeight) {
          newHeight = minHeight;
        }
      } else if (direction === 'bottom') {
        newHeight = originalHeight + (e.pageY - originalMouseY);
      }

      // Update state with new dimensions
      if (newWidthPercent >= minWidthPercent && newWidthPercent <= 100) {
        setDimensions(prev => ({ ...prev, widthPercent: newWidthPercent }));
      }
      if (newHeight >= minHeight) {
        setDimensions(prev => ({ ...prev, minHeight: newHeight }));
      }

      // Update droppedItems with new dimensions
      // updateDroppedItemsWithDimensions(newWidthPercent, newHeight);

      if (keyValueId) {
        const widthValue = `${newWidthPercent}`;
        const heightValue = `${newHeight}`;

        handleStyleChange("width", widthValue, "%", keyValueId);
        // handleStyleChange("height", heightValue, "px", keyValueId);
        handleStyleChange("min-height", heightValue, "px", keyValueId);
      }
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }, [dimensions, keyValueId]);

  useEffect(() => {
    if (keyValueId) {
      const styleObject = stylesArray.find(item => item.id === keyValueId);

      if (styleObject && styleObject.style) {
        const width = styleObject.style["width"];
        // const height = styleObject.style["height"];
        const height = styleObject.style["min-height"];

        const widthValue = width ? parseFloat(width) : 0;
        const heightValue = height ? parseFloat(height) : 0;

        if (widthValue !== dimensions.widthPercent || heightValue !== dimensions.minHeight) {
          setDimensions({ widthPercent: widthValue, minHeight: heightValue });
        }
      }
    }
  }, [stylesArray, keyValueId, dimensions]);

  // Function to handle element click and toggle visibility of resizers
  const outlinebluemain = (e) => {
    if (e.target.closest('.textbox-editable')) return;
    setClickedItemId(keyValueId)
    const element = e.target.closest('.resizable-web-template');
    console.log(element.id);

    if (element) {
      setActiveId(keyValueId);

      const deleteButton = document.getElementById(keyValueId + "delete");
      if (deleteButton) {
        deleteButton.classList.toggle("column-one-delete-web-template");
      }

      const resizers = document.getElementsByClassName(keyValueId); // Get all elements with the keyValueId class
      if (resizers.length > 0) {
        for (let i = 0; i < resizers.length; i++) {
          resizers[i].classList.toggle("displayblock");
        }
      }
    }
  };

  return (
    <div
      style={{ position: "relative" }}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      onDragOver={dragover}
      draggable="true"
      id='two-column-one'
      className="two-column-one-web-template"
      onClick={onClick}
    >
      <div className="two-column-1-web-template">
        {/* <div> */}
        <div
          className="resizable-web-template"
          // id={keyValueId}
          // style={{
          //   width: `${dimensions.widthPercent}%`, // Use percentage for width
          //   height: dimensions.height,
          //   top: dimensions.top,
          //   left: dimensions.left,
          // }}
          onClick={outlinebluemain}
        >
          {/* <div className="resizers-web-template"> */}
          <ColumnOneMain keyValueId={keyValueId} handleMouseDown={handleMouseDown} dimensions={dimensions} stylesArray={stylesArray} />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default ColumnOne;
