import React, { useState, useEffect, useContext } from "react";
import './KotStylesheet.css';
import { Link } from 'react-router-dom';
import OrderApi from "../../API/APIServices/Order";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from "../../Inventory/Props/Loader";
import AppHeaderDetail from "../AppHeaderDetail";
import Order from "../../API/APIServices/Order";
import MyContext from "../../Inventory/MyContext";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

const OrderDetails = () => {

    const navigate = useNavigate();

    const { isAutoTransferToKitchen } = useContext(MyContext);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ord1Id = searchParams.get('ord1Id');
    //const ord2Id = searchParams.get('ord2Id');

    const [loading, setLoading] = useState(true);

    const [Modal, setModal] = useState(false);

    const [ord2Id, setOrd2Id] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const filteredData = [
                {
                    operationType: 0,
                    path: "IsOpen",
                    op: "Add",
                    from: "string",
                    value: true
                },
            ]
            await OrderApi.updateOrderPartially(filteredData, ord1Id);
        }
        fetchData();
    }, [])

    const openStatusModal = async (id) => {
        setOrd2Id(id);
        await handleStatusClick(id);
        setModal(true);
        console.log(id);
    }
    const closeStatusModal = () => {
        setModal(false);
    };

    const [orderStatus, setOrderStatus] = useState([]);

    const handleCheckBoxChange = (e, itemId) => {
        const val = e.target.checked;
        const index = tableOrder.findIndex(item => item.ord2Id === itemId);

        if (index !== -1) {
            setOrderStatus(prevOrderStatus => {
                const updatedOrderStatus = [...prevOrderStatus];
                const updatedItem = {
                    ...tableOrder[index],
                    "o3OrderStatus": "Transferred"
                };
                if (val) {
                    updatedOrderStatus.push(updatedItem);
                } else {
                    const itemIndexToRemove = updatedOrderStatus.findIndex(item => item.ord2Id === itemId);
                    if (itemIndexToRemove !== -1) {
                        updatedOrderStatus.splice(itemIndexToRemove, 1);
                    }
                }
                return updatedOrderStatus;
            });
        }
    };
    // **************** Update Status ***************
    const UpdateStatus = async () => {
        setLoading(true)
        const currentDate = await CurrentDateAndTime.getCurrentDateTime();
        // const currentDate = new Date(isoString);
        console.log(insertStatus);

        for (var i = 0; i < updateStatus.length; i++) {
            let cookStatusValue;
            if (updateStatus[i].value === "Transferred")
                cookStatusValue = "Queue";
            else if (updateStatus[i].value === "Processing")
                cookStatusValue = "Process";
            else if (updateStatus[i].value === "Ready")
                cookStatusValue = "Complete";
            else
                cookStatusValue = updateStatus[i].value;

            const data = [
                {
                    operationType: 0,
                    path: "KotStatus",
                    op: "Add",
                    from: "string",
                    value: updateStatus[i].value
                },
                {
                    operationType: 0,
                    path: "CookStatus",
                    op: "Add",
                    from: "string",
                    value: cookStatusValue
                }
            ]
            await OrderApi.updateItemPartially(data, Number(ord2Id));

            // *********** If All Items are Delivered ----> update KOTDispatch Time *********
            if (insertStatus[i].orderStatus === "Delivered") {
                const filteredData = [
                    // {
                    //     fieldName: "ord1Id",
                    //     operatorName: "Equal",
                    //     compareValue: ord1Id,
                    // },
                    {
                        fieldName: "o2KotStatus",
                        operatorName: "NotEqual",
                        compareValue: "Delivered",
                    },
                ]
                const data = await Order.getOrderListByOrd1IdWithFilter(filteredData, ord1Id);
                if (data.length == 0) {
                    const time = await getCurrentTime();
                    const updatedValues = [
                        {
                            operationType: 0,
                            path: "KOTDispatchTime",
                            op: "Add",
                            from: "string",
                            value: time,
                        }
                    ]
                    await OrderApi.updateOrderPartially(updatedValues, ord1Id);
                }
            }
        }
        for (var i = 0; i < insertStatus.length; i++) {
            const data = [
                {
                    ord1Id: Number(insertStatus[i].ord1Id),
                    ord2Id: Number(insertStatus[i].ord2Id),
                    orderStatus: insertStatus[i].orderStatus,
                    orderDateTime: currentDate,
                }
            ]
            console.log(data);
            //await OrderApi.insertItemStatus(data);
            await OrderApi.insertItemStatusForKotStatusList(data);

            if (tableOrder[0].serviceTypeId == 751) {
                if (isAutoTransferToKitchen == true) {
                    if (updateOrderStatusAsProcess == true) {
                        if (insertStatus[i].orderStatus == "Processing") {
                            const updateStatus = await Order.updateOrderStatusAsProcess(insertStatus[i].ord1Id, insertStatus[i].ord2Id)
                            setUpdateOrderStatusAsProcess(false);
                        }
                    }
                }
            }
            debugger;
            if (tableOrder[0].serviceTypeId == 751) {
                if (isAutoTransferToKitchen == true) {
                    if (updateOrderStatusAsDelivered == true) {
                        if (insertStatus[i].orderStatus == "Delivered") {
                            const updateStatus = await Order.updateOrderStatusAsDelivered(insertStatus[i].ord1Id, insertStatus[i].ord2Id)
                            setUpdateOrderStatusAsDelivered(false);
                        }
                    }
                }
            }
        }
        setModal(false);
        setUpdateStatus([]);
        setInsertStatus([]);
        fetchData();
        setLoading(false);
    }
    // ************************* Login User Details ***********************
    const [loginUserDetails, setLoginUserDetails] = useState({});

    const [tableOrder, setTableOrder] = useState([]);

    const fetchData = async () => {
        setLoading(true)
        try {
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);
            setLoginUserDetails(loginUser);
            // const data = await OrderApi.getOrderItemReceivedList(ord1Id, loginUser.userId);    //"Processing", //OrderApi.getOrderListFilter()....... getOrderList()   //Loginuser
            const orderData = await OrderApi.getOrderItemWiseList(ord1Id);

            const data = orderData.filter(item => item.cancelApprovalStatus != "Approve")
            console.log(data);
            setTableOrder(data);
            setLoading(false)
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);   //UpdateStatus

    const transferToKitchen = async () => {
        const currentDate = await CurrentDateAndTime.getCurrentDateTime();
        // const currentDate = new Date(isoString);

        for (var i = 0; i < orderStatus.length; i++) {
            const updatedValues =
            {
                ord1Id: orderStatus[i].ord1Id,
                ord2Id: orderStatus[i].ord2Id,
                orderStatus: orderStatus[i].o3OrderStatus,
                orderDateTime: currentDate,
            }
            await OrderApi.insertItemStatus(updatedValues);
        }

        const data = [
            {
                operationType: 0,
                path: "KotStatus",
                op: "Add",
                from: "string",
                value: "Transferred"
            },
            {
                operationType: 0,
                path: "CookStatus",
                op: "Add",
                from: "string",
                value: "Queue"
            }
        ]
        for (var i = 0; i < orderStatus.length; i++) {
            const ord2Id = orderStatus[i].ord2Id;
            await OrderApi.updateItemPartially(data, ord2Id);
        }
        alert("Transferred Successfully");
        console.log(orderStatus);
        fetchData();
    }

    // ******************** status wise list ***********************
    const [statusList, setStatusList] = useState([]);

    const [ReceivedStatus, setRecivedStatus] = useState([]);
    const [TransferredStatus, setTransferredStatus] = useState([]);
    const [ProcessingStatus, setProcessingStatus] = useState([]);
    const [ReadyStatus, setReadyStatus] = useState([]);
    const [DeliveredStatus, setDeliveredStatus] = useState([]);
    const [PickupStatus, setPickupStatus] = useState([]);

    const handleStatusClick = async (ord2Id) => {
        setLoading(true)
        try {
            const data = await OrderApi.getItemWiseStatusList(ord1Id, ord2Id);
            console.log(data);
            setStatusList(data);
            setRecivedStatus([]);
            setTransferredStatus([]);
            setProcessingStatus([]);
            setReadyStatus([]);
            setDeliveredStatus([]);
            setPickupStatus([]);

            const setStatus = (status, stausCreatedBy, statusDateTime) => {
                console.log(status, stausCreatedBy, statusDateTime);
                const val = [{
                    orderStatus: status,
                    stausCreatedBy: stausCreatedBy,
                    statusDateTime: statusDateTime
                }];
                switch (status) {
                    case "Received":
                        setRecivedStatus(val);
                        break;
                    case "Transferred":
                        setTransferredStatus(val);
                        break;
                    case "Processing":
                        setProcessingStatus(val);
                        break;
                    case "Ready":
                        setReadyStatus(val);
                        break;
                    case "Delivered":
                        setDeliveredStatus(val);
                        break;
                    case "Pickup":
                        setPickupStatus(val);
                        break;
                    default:
                        break;
                }
            };
            for (let i = 0; i < data.length; i++) {
                // const { orderStatus, stausCreatedBy, statusDateTime } = data[i];
                setStatus(data[i].orderStatus, data[i].stausCreatedBy, data[i].statusDateTime);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false);
    }
    const [updateStatus, setUpdateStatus] = useState([]);
    const [insertStatus, setInsertStatus] = useState([]);

    async function getCurrentTime() {
        const isoString = await CurrentDateAndTime.getCurrentTime();
        return isoString;
    }

    const [updateOrderStatusAsProcess, setUpdateOrderStatusAsProcess] = useState(false);
    const [updateOrderStatusAsDelivered, setUpdateOrderStatusAsDelivered] = useState(false);

    const handleStatusCheckBoxChange = async (e, val) => {
        const checked = e.target.checked;
        console.log(checked);

        if (tableOrder[0].serviceTypeId == 751) {
            if (val === "Processing") {
                setUpdateOrderStatusAsProcess(true)
            }
        }
        if (tableOrder[0].serviceTypeId == 751) {
            if (val === "Delivered") {
                setUpdateOrderStatusAsDelivered(true)
            }
        }

        if (checked) {
            setUpdateStatus(prevCheckboxes => [
                ...prevCheckboxes,
                {
                    operationType: 0,
                    path: "KotStatus",
                    op: "Add",
                    from: "string",
                    value: val
                }
            ]);

            setInsertStatus(prevValues => [
                ...prevValues,
                {
                    ord1Id: ord1Id,
                    ord2Id: ord2Id,
                    orderStatus: val,
                    orderDateTime: "",
                }
            ]);
            console.log(insertStatus);
        }
        else {
            setUpdateStatus(prevCheckboxes => (
                prevCheckboxes.filter(item => item.value !== val)
            ));
            setInsertStatus(prevCheckboxes => (
                prevCheckboxes.filter(item => item.orderStatus !== val)
            ));
        }
    }

    //  useEffect(() => {
    //      const fetchData = async () => {
    //          try {
    //              const data = await OrderApi.getItemWiseStatusList(ord1Id, ord2Id);
    //              console.log(data);
    //              setStatusList(data);
    //          } catch (error) {
    //              console.error("Error fetching data:", error);
    //          }
    //      };
    //      fetchData();
    //  }, [])

    const handlePrint = (orderNo, itemName, preferenceNo, qty, sku, ord2Id) => {
        // debugger;
        // console.log(typeof OrderDetails.destroy)
        // if (typeof OrderDetails.destroy === 'undefined') {
        //     alert("hello");
        //     OrderDetails.destroy(); // Call the destroy function only if it exists
        // } else {
        //     console.log('destroy function does not exist or is not a function');
        // }

        navigate("/KotPrintPage", {
            state: {
                orderNo,
                itemName,
                preferenceNo,
                qty,
                sku,
                ord2Id
            }
        });
    }

    return (
        <div style={{ backgroundColor: "white" }}>
            <AppHeaderDetail title={`Details of Order No ${tableOrder.length > 0 ? tableOrder[0].orderNo : 'Loading...'}`} setLoading={setLoading} fetchData={fetchData} />
            <div style={{ backgroundColor: "ghostwhite", marginTop: "45px", marginBottom: "80px" }}>
                <div className="detailTopDiv">
                    <div className="">
                        <div className="space-between">
                            {/* <label className="ques" style={{ color: "white" }}>Particulars({tableOrder.length > 0 ? tableOrder[0].orderNo : 'Loading...'})</label> */}
                            <label className="ques" style={{ color: "white" }}>Order No. {tableOrder.length > 0 ? tableOrder[0].orderNo : 'Loading...'}</label>
                            <div className="flex-start" style={{ paddingTop: "0px" }}>
                                <label className="ques" style={{ color: "white" }}>Table - &nbsp;</label>
                                <label className="ans" style={{ color: "white" }}>{tableOrder.length > 0 ? tableOrder[0].tableName : 'Loading...'} &nbsp;</label>
                            </div>
                        </div>
                        <div className="flex-start">
                            <label className="ques" style={{ color: "white" }}>Person -&nbsp;</label>
                            <label className="ans" style={{ color: "white" }}>{tableOrder.length > 0 ? tableOrder[0].noOfPerson : 'Loading...'} =&gt; Child- {tableOrder.length > 0 ? tableOrder[0].noChildPerson : 'Loading...'}, Adult-{tableOrder.length > 0 ? tableOrder[0].noAdultPerson : 'Loading...'} &nbsp;</label>
                        </div>
                    </div>
                </div>
                {(() => {
                    if (tableOrder !== null && tableOrder.length > 0) {
                        // Sort the tableOrder array based on the preferenceNo property
                        const sortedTableOrder = tableOrder.slice().sort((a, b) => a.preferenceNo - b.preferenceNo);
                        return (
                            sortedTableOrder.filter(item => item.kotStatus != "Pending").map((data, index) => {
                                console.log(sortedTableOrder);
                                let backgroundColor = "white";
                                console.log(data.itemRunningQty);
                                if (data.itemRunningQty > 0) {
                                    backgroundColor = 'rgb(249, 214, 214, 0.6)'
                                }
                                return (
                                    <div className="listDiv" style={{ backgroundColor: backgroundColor, marginTop: "10px" }}>
                                        <div className="space-between">
                                            <label className="ques">{data.d2ItemName}</label>
                                            {/* <div className="numCircle">
                                            <label className="ques c-w" style={{ color: "white", position: "absolute" }}>{data.qty}</label>
                                        </div> */}
                                            <div className="circle-red">
                                                <span className="circle-text-red">{data.qty}</span>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">SKU : &nbsp;</label>
                                                <label className="ans">{data.sku}</label>
                                                {/* <label className="ans">{data.sku} ---- {data.qty}</label> */}
                                            </div>
                                            <input
                                                type="checkbox"
                                                style={{ display: data.kotStatus === "Received" ? "block" : "none", marginRight: "3px" }}
                                                onChange={(e) => handleCheckBoxChange(e, data.ord2Id)}
                                            />
                                        </div>
                                        {/* <div className="flex-start">
                                        <label className="ques">Cook Status : &nbsp;</label>
                                        <label className="ques" style={{ color: "green" }}>{data.o2KotStatus}</label>
                                    </div> */}
                                        <div className="flex-start">
                                            <label className="ques">Cooking Preference : &nbsp;</label>
                                            <label className="ans">{data.preferenceNo}</label>
                                        </div>
                                        <div className="flex-start" style={{ display: data.itemSuggestion == "" ? "none" : "block" }}>
                                            <label className="ques" >Item Suggestion : &nbsp;</label>
                                            <label className="ans" style={{ color: "darkgreen", fontWeight: "600" }}>{data.itemSuggestion}</label>
                                        </div>

                                        <div className="flex-start">
                                            <label className="ques">Confirm Date : &nbsp;</label>
                                            <label className="ans" style={{ display: !data.itemConfirmedByName ? "none" : "block" }}>{moment.utc(data.itemConfirmedOn1).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Confirm By : &nbsp;</label>
                                            <label className="ans">{data.itemConfirmedByName}</label>
                                        </div>
                                        {/* <div className="flex-start">
                                        <label className="ques">Cook Status : &nbsp;</label>
                                        <label className="ques" style={{ color: "black " }}>{data.cookStatus}</label>
                                    </div> */}
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Kot Status : &nbsp;</label>
                                                <label className="ques" style={{ color: "hotpink " }}>{data.kotStatus} &nbsp;</label>
                                                <i className="fa-solid fa-pen" onClick={() => openStatusModal(data.ord2Id)} style={{ fontSize: "14px", marginTop: "5px" }}></i>
                                            </div>
                                            {/* <Link to={`/KotPrintPage?orderNo=${data.orderNo}&d2ItemName=${data.itemName}&preferenceNo=${data.preferenceNo}&qty=${data.qty}&sku=${data.sku}`} style={{ textDecoration: 'none' }}>
                                            <i className="fa-solid fa-print"></i>
                                            </Link> */}
                                            {/* <i className="fa-solid fa-print" onClick={() => handlePrint(data.orderNo, data.itemName, data.preferenceNo, data.qty, data.sku, data.ord2Id)}></i> */}
                                            <div onClick={() => handlePrint(data.orderNo, data.d2ItemName, data.preferenceNo, data.qty, data.sku, data.ord2Id)}>
                                                <i className="fa-solid fa-print"></i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )
                    }
                })()}
                {/*******************/}
            </div>
            <div className="floating-button-container" style={{ width: "85%", bottom: "20px" }}>
                <button className="blueBtn" onClick={transferToKitchen}>Transfer to Kitchen</button>
            </div>
            {/************************** Kot Status Modal ***************/}
            {Modal && (
                <div className="modal-back1">
                    <div className="kotStatus-modal" style={{ maxHeight: "90%", overflow: "scroll" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                            <label className="fw-600" style={{ fontSize: "18px" }}>KOT Status</label>
                            <i className="fa-solid fa-xmark xmark" onClick={closeStatusModal}></i>
                        </div>
                        <hr />
                        <div className="">
                            <div className="flex-start">
                                <div style={{ marginLeft: "10px" }}>
                                    {/* {statusList.map((data, index) => ( */}
                                    <div>
                                        <div className="flex-start">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleStatusCheckBoxChange(e, "Received")}
                                                className="mt-1"
                                                //checked= {ReceivedStatus.length > 0 && ReceivedStatus[0].orderStatus === "Received" ? true : false}
                                                disabled={ReceivedStatus.length > 0 && ReceivedStatus[0].orderStatus === "Received"} />  {/*{checkboxEnabled.Received}*/}
                                            <div className="flex-start">
                                                <label>&nbsp;Received</label>
                                                {ReceivedStatus.length > 0 && ReceivedStatus[0].orderStatus === "Received" && (
                                                    <div>
                                                        <label>&nbsp;by <label style={{ fontWeight: "600" }}>{ReceivedStatus[0].stausCreatedBy}</label>,</label>
                                                        <label>Time&nbsp;:&nbsp;{moment.utc(ReceivedStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex-start">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleStatusCheckBoxChange(e, "Transferred")}
                                                className="mt-1"
                                                // checked={TransferredStatus.length > 0 && TransferredStatus[0].orderStatus === "Transferred" ? true : false}
                                                disabled={TransferredStatus.length > 0 && TransferredStatus[0].orderStatus === "Transferred"}//? true : (ReceivedStatus.length > 0 && ReceivedStatus[0].orderStatus === "Received" ? false : true)}
                                            />
                                            <div className="flex-start">
                                                <label>&nbsp;Transferred</label>
                                                {TransferredStatus.length > 0 && TransferredStatus[0].orderStatus === "Transferred" && (
                                                    <div>
                                                        <label>&nbsp;by <label style={{ fontWeight: "600" }}>{TransferredStatus[0].stausCreatedBy}</label>,</label>
                                                        <label>Time&nbsp;:&nbsp;{moment.utc(TransferredStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex-start">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleStatusCheckBoxChange(e, "Processing")}
                                                className="mt-1"
                                                disabled={ProcessingStatus.length > 0 && ProcessingStatus[0].orderStatus === "Processing"}//? true : (TransferredStatus.length > 0 && TransferredStatus[0].orderStatus === "Transferred" ? false : true)}
                                            //checked={ProcessingStatus.length > 0 && ProcessingStatus[0].orderStatus === "Processing"}
                                            />
                                            <div className="flex-start">
                                                <label>&nbsp;Processing</label>
                                                {ProcessingStatus.length > 0 && ProcessingStatus[0].orderStatus === "Processing" && (
                                                    <div>
                                                        <label>&nbsp;by <label style={{ fontWeight: "600" }}>{ProcessingStatus[0].stausCreatedBy}</label>,</label>
                                                        <label>Time&nbsp;:&nbsp;{moment.utc(ProcessingStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex-start">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleStatusCheckBoxChange(e, "Ready")}
                                                className="mt-1"
                                                // checked={ReadyStatus.length > 0 && ReadyStatus[0].orderStatus === "Ready" ? true : false}
                                                disabled={ReadyStatus.length > 0 && ReadyStatus[0].orderStatus === "Ready"}//? true : (ProcessingStatus.length > 0 && ProcessingStatus[0].orderStatus === "Processing" ? false : true)}
                                            />
                                            <div className="flex-start">
                                                <label>&nbsp;Ready</label>
                                                {ReadyStatus.length > 0 && ReadyStatus[0].orderStatus === "Ready" && (
                                                    <div>
                                                        <label>&nbsp;by <label style={{ fontWeight: "600" }}>{ReadyStatus[0].stausCreatedBy}</label>,</label>
                                                        <label>Time&nbsp;:&nbsp;{moment.utc(ReadyStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* <div className="flex-start">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleStatusCheckBoxChange(e, "Pickup")}
                                                className="mt-1"
                                                // checked={PickupStatus.length > 0 && PickupStatus[0].orderStatus === "Pickup" ? true : false}
                                                disabled={PickupStatus.length > 0 && PickupStatus[0].orderStatus === "Pickup"}//? true : (ReadyStatus.length > 0 && ReadyStatus[0].orderStatus === "Ready" ? false : true)}
                                            />
                                            <div className="flex-start">
                                                <label>&nbsp;Pickup</label>
                                                {PickupStatus.length > 0 && PickupStatus[0].orderStatus === "Pickup" && (
                                                    <div>
                                                        <label>&nbsp;by <label style={{ fontWeight: "600" }}>{PickupStatus[0].stausCreatedBy}</label>,</label>
                                                        <label>Time&nbsp;:&nbsp;{moment.utc(PickupStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </div> */}

                                        <div className="flex-start">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleStatusCheckBoxChange(e, "Delivered")}
                                                className="mt-1"
                                                // checked={DeliveredStatus.length > 0 && DeliveredStatus[0].orderStatus === "Delivered" ? true : false}
                                                disabled={DeliveredStatus.length > 0 && DeliveredStatus[0].orderStatus === "Delivered"}//? true : (PickupStatus.length > 0 && PickupStatus[0].orderStatus === "Pickup" ? false : true)}
                                            />
                                            <div className="flex-start">
                                                <label>&nbsp;Delivered</label>
                                                {DeliveredStatus.length > 0 && DeliveredStatus[0].orderStatus === "Delivered" && (
                                                    <div>
                                                        <label>&nbsp;by <label style={{ fontWeight: "600" }}>{DeliveredStatus[0].stausCreatedBy}</label>,</label>
                                                        <label>Time&nbsp;:&nbsp;{moment.utc(DeliveredStatus[0].statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* <StatusInfo status="Transferred" statusArray={TransferredStatus} handleChange={(e) => handleStatusCheckBoxChange(e, "Transferred")} />
                                        <StatusInfo status="Processing" statusArray={ProcessingStatus} handleChange={(e) => handleStatusCheckBoxChange(e, "Processing")} />
                                        <StatusInfo status="Ready" statusArray={ReadyStatus} handleChange={(e) => handleStatusCheckBoxChange(e, "Ready")} />
                                        <StatusInfo status="Delivered" statusArray={DeliveredStatus} handleChange={(e) => handleStatusCheckBoxChange(e, "Delivered")} />
                                        <StatusInfo status="Pickup" statusArray={PickupStatus} handleChange={(e) => handleStatusCheckBoxChange(e, "Pickup")} /> */}
                                    </div>
                                    {/* ))} */}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                            <button className="greenBtn" style={{ width: "50%" }} onClick={UpdateStatus}>Update Status</button>
                            <button className="redBtn" style={{ width: "30%" }} onClick={closeStatusModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
            {loading && <Loader />}
        </div>
    );
};

export default OrderDetails;
