import React, { useContext, useRef, useEffect, useState } from "react";
import ColumnOne from "../Components/DraggableComponent/ColumnOne";
import TwoColumn from "../Components/DraggableComponent/TwoColumn";
import ThreeColumn from "../Components/DraggableComponent/ThreeColumn";
import ThreeAndSevenColumn from "../Components/DraggableComponent/ThreeAndSevenColumn";
import Text from "../Components/DraggableComponent/Text";
import Link from "../Components/DraggableComponent/Link";
import Image from "../Components/DraggableComponent/Image";
import Video from "../Components/DraggableComponent/Video";
import Map from "../Components/DraggableComponent/Map";
import LinkBlock from "../Components/DraggableComponent/LinkBlock";
import Quote from "../Components/DraggableComponent/Quote";
import TextSection from "../Components/DraggableComponent/TextSection";
import Form from "../Components/DraggableComponent/Form";
import Input from "../Components/DraggableComponent/Input";
import TextArea from "../Components/DraggableComponent/TextArea";
import Select from "../Components/DraggableComponent/Select";
import Button from "../Components/DraggableComponent/Button";
import Label from "../Components/DraggableComponent/Label";
import CheckBox from "../Components/DraggableComponent/CheckBox";
import Radio from "../Components/DraggableComponent/Radio";
import CountDown from "../Components/DraggableComponent/CountDown";
import Tabs from "../Components/DraggableComponent/Tabs";
import CustonCode from "../Components/DraggableComponent/CustonCode";
import ToolTip from "../Components/DraggableComponent/ToolTip";
import Typed from "../Components/DraggableComponent/Typed";
import ImageSlider from "../Components/DraggableComponent/ImageSlider";
import ToggleBox from "../Components/DraggableComponent/ToggleBox";
import IconBox from "../Components/DraggableComponent/IconBox";
import AlertBox from "../Components/DraggableComponent/AlertBox";
import SearchBox from "../Components/DraggableComponent/SearchBox";
import IconLibrary from "../Components/DraggableComponent/IconLibrary";
import NavBarTypes from "../Components/DraggableComponent/NavBarTypes";
import LogoBox from "../Components/DraggableComponent/LogoBox";
import Footer from "../Components/DraggableComponent/Footer";
import MyContext from "../../Inventory/MyContext";
import Calendar from "../Components/DraggableComponent/Calender";
import Box from "../Components/DraggableComponent/Box";
import Paragraph from "../Components/DraggableComponent/Paragraph";
import Heading from "../Components/DraggableComponent/Heading";
import TextBox from "../Components/DraggableComponent/TextBox";
import Table from "../Components/DraggableComponent/Table";
import VerticalLine from "../Components/DraggableComponent/VerticalLine";
import TableHeader from "../Components/DraggableComponent/TableHeader";
import TableDetail from "../Components/DraggableComponent/TableDetail";
import TableFooter from "../Components/DraggableComponent/TableFooter";

import Ruler from "./Ruler";
import HorizontalLine from "../Components/DraggableComponent/HorizontalLine";
import RupeeSymbol from "../Components/DraggableComponent/RupeeSymbol";
import Square from "../Components/DraggableComponent/Square";

const Home = (props) => {
  const { TemplateData, setTemplateData, droppedItems, setDroppedItems, setClickedItemId, setClickedItemName, setStylesArray, stylesArray } = useContext(MyContext);

  const dropTargetRef = useRef(null);
  const [hoveredComponentId, setHoveredComponentId] = useState(null);
  const componentMap = {
    "1": ColumnOne,
    "2": TwoColumn,
    "3": ThreeColumn,
    "4": ThreeAndSevenColumn,
    "5": Text,
    "6": Link,
    "7": Image,
    "8": Video,
    "9": Map,
    "10": LinkBlock,
    "11": Quote,
    "12": TextSection,
    "13": Form,
    "14": Input,
    "15": TextArea,
    "16": Select,
    "17": Button,
    "18": Label,
    "19": CheckBox,
    "20": Radio,
    "21": CountDown,
    "22": Tabs,
    "23": CustonCode,
    "24": ToolTip,
    "25": Typed,
    "26": ImageSlider,
    "27": ToggleBox,
    "28": Calendar,
    "29": AlertBox,
    "30": IconBox,
    "31": SearchBox,
    "32": IconLibrary,
    "33": NavBarTypes,
    "34": LogoBox,
    "35": Footer,
    "36": Box,
    "37": Paragraph,
    "38": Heading,
    "39": TextBox,
    "40": Table,
    "41": VerticalLine,
    "42": TableHeader,
    "43": TableDetail,
    "44": TableFooter,
    "45": HorizontalLine,
    "46": RupeeSymbol,
    "47": Square,
    "48": ColumnOne,
    "49": ColumnOne
  };

  const componentNames = {
    "1": "ColumnOne",
    "2": "TwoColumn",
    "3": "ThreeColumn",
    "4": "ThreeAndSevenColumn",
    "5": "Text",
    "6": "Link",
    "7": "Image",
    "8": "Video",
    "9": "Map",
    "10": "LinkBlock",
    "11": "Quote",
    "12": "TextSection",
    "13": "Form",
    "14": "Input",
    "15": "TextArea",
    "16": "Select",
    "17": "Button",
    "18": "Label",
    "19": "CheckBox",
    "20": "Radio",
    "21": "CountDown",
    "22": "Tabs",
    "23": "CustonCode",
    "24": "ToolTip",
    "25": "Typed",
    "26": "ImageSlider",
    "27": "ToggleBox",
    "28": "Calendar",
    "29": "AlertBox",
    "30": "IconBox",
    "31": "SearchBox",
    "32": "IconLibrary",
    "33": "NavBarTypes",
    "34": "LogoBox",
    "35": "Footer",
    "36": "Box",
    "37": "Paragraph",
    "38": "Heading",
    "39": "TextBox",
    "40": "Table",
    "41": "Vertical Lines",
    "42": "Table Header",
    "43": "Table Detail",
    "44": "Table Footer",
    "45": "Horizontal Lines",
    "46": "RupeeSymbol",
    "47": "Square",
    "48": "Header",
    "49": "Footer"
  };

  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  useEffect(() => {
    console.log("undoStack", undoStack);
  }, [undoStack]);

  useEffect(() => {
    console.log("redoStack", redoStack);
  }, [redoStack]);
  // Function to handle mouse enter
  const handleMouseEnter = (id) => {
    setHoveredComponentId(id);
  };

  const handleMouseLeave = () => {
    setHoveredComponentId(null);
  };

  const generateHexId = (existingIds) => {
    let id;
    do {
      id = Math.random().toString(16).slice(2, 16).toLowerCase();
    } while (existingIds.has(id));
    return id;
  };
  const existingIds = new Set();


  const handleDrop = (e) => {
    e.preventDefault();
    const newId = generateHexId(existingIds);

    const itemIds = e.dataTransfer.getData("text").split(",");
    console.log(itemIds);
    console.log(componentNames);

    // Check if `droppedItems` already contains an item with ID matching `itemIds[0]`
    const itemIdToCheck = itemIds[0];
    console.log(itemIdToCheck);
    const itemExists = droppedItems.some(item => item.id == itemIdToCheck);
    console.log(itemExists);
    if (itemExists) {
      console.log(`Item with ID ${itemIdToCheck} already exists.`);
      return;
    }
    console.log(itemIdToCheck);
    console.log(itemExists);

    // Create a new item object
    const newItem = {
      id: newId,
      items: itemIds,
      dropAreaId: e.timeStamp,
      textContents: "", // Initialize with empty content
      name: itemIds.map(id => componentNames[id] || "Unknown").join(", "), // Convert to string with commas
      JSONChildComponents: []
    };

    const itemsToAdd = [newItem];
    if (itemIds.includes("2")) { // Assuming "2" is the ID for TwoColumn

      const secondaryItem = {
        id: newId + "CHILD",
        items: [],
        dropAreaId: e.timeStamp,
        textContents: "",
        name: itemIds.map(id => componentNames[id] || "Unknown").join(", "),
        JSONChildComponents: []
      };
      itemsToAdd.push(secondaryItem);
    }
    if (itemIds.includes("3")) { // Assuming "2" is the ID for TwoColumn

      const secondaryItem = {
        id: newId + "CHILD",
        items: [],
        dropAreaId: e.timeStamp,
        textContents: "",
        name: itemIds.map(id => componentNames[id] || "Unknown").join(", "),
        JSONChildComponents: []
      };
      const secondaryItem1 = {
        id: secondaryItem.id + 1,
        items: [],
        dropAreaId: e.timeStamp,
        textContents: "",
        name: itemIds.map(id => componentNames[id] || "Unknown").join(", "),
        JSONChildComponents: []
      };
      itemsToAdd.push(secondaryItem, secondaryItem1);
    }

    setUndoStack(prev => [...prev, droppedItems]);

    setRedoStack([]);
    // Update the droppedItems state with new data
    setDroppedItems(prevItems => {
      const updatedItems = [...prevItems, ...itemsToAdd];

      // Update the template data with new content
      setTemplateData(prevState => ({
        ...prevState,
        jsonStructure: updatedItems,
      }));

      // Fetch and update the inner HTML of the dropped component with a delay

      if (itemsToAdd) {
        itemsToAdd.map(item => (
          setTimeout(() => fetchInnerHtmlOfDroppedComponent(item.id), 1000)
        ))
      } else {
        setTimeout(() => fetchInnerHtmlOfDroppedComponent(newItem.id), 1000)
      }
      return updatedItems;
    });
  };

  const handleUndo = () => {
    debugger
    if (undoStack.length === 0) {
      console.log("No actions to undo");
      return;
    }

    const previousState = undoStack[undoStack.length - 1];
    setRedoStack(prev => [...prev, droppedItems]);
    setDroppedItems(previousState);
    setUndoStack(prev => prev.slice(0, -1));
  };

  const handleRedo = () => {
    if (redoStack.length === 0) {
      console.log("No actions to redo");
      return;
    }

    const nextState = redoStack[redoStack.length - 1];
    setDroppedItems(nextState); // Restore the next state
    setRedoStack(prev => prev.slice(0, -1)); // Remove the last state from redo stack
    setUndoStack(prev => [...prev, nextState]); // Push the current state to undo stack
  };

  // Listen for keyboard shortcuts for undo (Ctrl + Z) and redo (Ctrl + Y)
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey || e.metaKey) {
        if (e.key === 'z') {
          e.preventDefault();
          handleUndo();
        } else if (e.key === 'y') {
          e.preventDefault();
          handleRedo();
        }
      }
    };

    // Add the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [droppedItems, undoStack, redoStack]);

  const fetchInnerHtmlOfDroppedComponent = (componentId) => {
    console.log(componentId);

    const getRemainingData = (str) => {
      if (str.endsWith("CHILD")) {
        return str.replace("CHILD", "");
      } else if (str.endsWith("CHILD1")) {
        return str.replace("CHILD1", "");
      }
      return str;
    };

    var newID = getRemainingData(componentId)

    if (componentId.endsWith("CHILD") || componentId.endsWith("CHILD1")) {
      const componentElement = document.getElementById(newID.toString() + "innerHTML");
      if (componentElement) {
        // Get the inner HTML
        const innerHTML = componentElement.outerHTML;
        console.log(innerHTML);

        // Update the corresponding item with the inner HTML
        setDroppedItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.id === newID) {
              return { ...item, textContents: innerHTML };
            }
            return item;
          });

          // Update the template data with the new content
          setTemplateData(prevState => ({
            ...prevState,
            jsonStructure: updatedItems,
          }));

          return updatedItems;
        });
      }
      return
    }
    // Find the component element using its ID
    const componentElement = document.getElementById(componentId.toString());


    console.log(componentElement);
    if (componentElement) {
      // Get the inner HTML
      const innerHTML = componentElement.outerHTML;
      console.log(innerHTML);

      // Update the corresponding item with the inner HTML
      setDroppedItems(prevItems => {
        const updatedItems = prevItems.map(item => {
          if (item.id === componentId) {
            return { ...item, textContents: innerHTML };
          }
          return item;
        });

        // Update the template data with the new content
        setTemplateData(prevState => ({
          ...prevState,
          jsonStructure: updatedItems,
        }));

        return updatedItems;
      });
    }
  };

  // const handleRemoveItem = (itemId) => {
  //   setDroppedItems(prevItems => {

  //     const idToRemove = itemId;
  //     const idToRemoveNext = itemId + "CHILD";
  //     const idToRemoveNext1 = idToRemoveNext + "1";


  //     const updatedItems = prevItems.filter(item =>
  //       item.id !== idToRemove && item.id !== idToRemoveNext && item.id !== idToRemoveNext1
  //     );
  //     // const updatedItems = prevItems.filter(item => item.id !== itemId);

  //     setTemplateData(prevState => ({
  //       ...prevState,
  //       jsonStructure: updatedItems,
  //     }));
  //     setStylesArray((prevStyles) => {
  //       return prevStyles.filter(styleObj => styleObj.id !== itemId);
  //     });
  //     return updatedItems;
  //   });
  // };

  /* Copy Paste any component */
  const [clipboard, setClipboard] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  // useEffect(() => {
  //   console.log("selectedItemId for Copy", selectedItemId);
  //   console.log("clipboard", clipboard);
  // }, [selectedItemId, clipboard]);

  const handleCopy = (itemId) => {
    const itemToCopy = droppedItems.find(item => item.id === itemId);
    const styleToCopy = stylesArray.find(style => style.id === itemId);
    if (itemToCopy) {
      setClipboard({ item: itemToCopy, style: styleToCopy });
    }
  };

  const handlePaste = () => {
    debugger
    if (clipboard) {
      const newItemId = generateHexId(existingIds);
      const copiedItem = { ...clipboard.item, id: newItemId };
      const copiedStyle = { ...clipboard.style, id: newItemId };

      setDroppedItems(prevItems => [...prevItems, copiedItem]);
      setTemplateData(prevState => ({
        ...prevState,
        jsonStructure: [...prevState.jsonStructure, copiedItem],
      }));

      if (copiedStyle) {
        setStylesArray(prevStyles => [...prevStyles, copiedStyle]);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === 'c') {
      if (selectedItemId) {
        e.preventDefault();
        handleCopy(selectedItemId);
      }
    }
    if (e.ctrlKey && e.key === 'v') {
      e.preventDefault()
      handlePaste();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [clipboard, droppedItems, stylesArray]);

  const getStyle = (id) => {
    const style = stylesArray.find(style => style.id === id);
    return style ? style.styles : {};
  };
  /* Copy Paste any component */


  const handleRemoveItem = (itemId) => {
    setClickedItemId('')
    setClickedItemName('')
    setDroppedItems(prevItems => {

      const idToRemove = itemId;
      const idToRemoveNext = itemId + "CHILD";
      const idToRemoveNext1 = idToRemoveNext + "1";

      // **New Code Block Start: Identify child components**
      // Find the item corresponding to the itemId
      const parentItem = prevItems.find(item => item.id === itemId);
      let idsToRemove = [idToRemove, idToRemoveNext, idToRemoveNext1];

      if (parentItem && parentItem.JSONChildComponents.length > 0) {
        // If the item has child components, add their ids to the removal list
        const childIds = parentItem.JSONChildComponents.map(child => child.id);
        idsToRemove = idsToRemove.concat(childIds);
      }
      // **New Code Block End**

      // **Modified Code: Filter out the items that need to be removed**
      const updatedItems = prevItems.filter(item =>
        !idsToRemove.includes(item.id)
      );

      setTemplateData(prevState => ({
        ...prevState,
        jsonStructure: updatedItems,
      }));
      setStylesArray((prevStyles) => {
        // **Modified Code: Remove the corresponding styles**
        return prevStyles.filter(styleObj => !idsToRemove.includes(styleObj.id));
      });
      return updatedItems;
    });
  };

  const dragstart = (e, id) => {
    e.dataTransfer.setData("text/plain", id.toString());
  };

  const dragover = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, dropid) => {
    e.preventDefault();
    const dragId = e.dataTransfer.getData("text/plain");
    const dragIndex = droppedItems.findIndex(item => item.id === dragId);
    const dropIndex = droppedItems.findIndex(item => item.id === dropid);

    console.log(dragId, dragIndex);
    console.log(dropid, dropIndex);

    if (dragIndex === -1 || dropIndex === -1 || dropIndex == dragIndex) {
      return;
    }

    const newArray = [...droppedItems];
    const newDragItem = droppedItems[dragIndex];
    const newDropItem = droppedItems[dropIndex];

    newArray[dragIndex] = newDropItem;
    newArray[dropIndex] = newDragItem;

    console.log(newArray);

    setDroppedItems(newArray);
    e.stopPropagation()
  };

  // useEffect(() => {
  //   const savedItems = localStorage.getItem("droppedItems");
  //   if (savedItems) {
  //     setDroppedItems(JSON.parse(savedItems));
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem("droppedItems", JSON.stringify(droppedItems));
  // }, [droppedItems]);

  const handleComponentClick = (id) => {
    console.log(`Clicked component ID: ${id}`);
    setClickedItemId(id);
  };


  const canvasWidth = 1000;
  const canvasHeight = 539;
  const step = 10;


  const canvasRef = useRef(null); // Ref to store the canvas element

  useEffect(() => {
    // Function to handle clicks outside of the canvas
    const handleClickOutside = (event) => {

      // console.log("Click detected: ", event.target);
      if (canvasRef.current && !canvasRef.current.contains(event.target)) {
        console.log("Clicked outside the canvas.");
        hideDeleteButtons();
      } else {
        console.log("Clicked inside the canvas.");
      }
    };


    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const hideDeleteButtons = () => {
    const deleteButtons = document.querySelectorAll('.column-one-delete-web-template');
    deleteButtons.forEach((button) => {
      button.classList.remove('column-one-delete-web-template');
    });


    const resizers = document.querySelectorAll('.resizer-web-template');
    // console.log("Hiding resizers:", resizers.length);
    resizers.forEach((resizer) => {
      resizer.classList.remove("displayblock");

    });
  }

  const componentsWithoutDelete = new Set([39, 40, 41, 42, 43, 44, 45, 46]);


  return (
    <div
      className="drop-target-web-template"
      id="drop-target-innerHtml"
      ref={canvasRef}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    // style={{
    //   position: 'relative',
    //   width: `${canvasWidth}px`,
    //   height: `${canvasHeight}px`,
    //   border: '1px solid #ccc',
    // }}
    >
      {/* <Ruler width={canvasWidth} height={canvasHeight} step={step} /> */}
      {droppedItems.map(droppedItem => (
        <div key={droppedItem.id} className="dropped-item">
          {droppedItem.items.map(itemId => {
            const Component = componentMap[itemId];
            if (Component) {
              const componentId = `component-${itemId}`;
              // const isHovered = hoveredComponentId === droppedItem.id;
              return (
                <div
                  id={componentId}
                  key={componentId}
                  data-component-id={droppedItem.id}
                  style={{ opacity: "1", position: "relative", ...getStyle(droppedItem.id) }}
                  draggable
                  onDragStart={(e) => dragstart(e, droppedItem.id)}
                  onDragOver={(e) => dragover(e)}
                  onDrop={(e) => onDrop(e, droppedItem.id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    // setClickedItemId(droppedItem.id);
                    // handleComponentClick(droppedItem.id);
                    setClickedItemName(componentNames[itemId])
                    setSelectedItemId(droppedItem.id);
                  }}
                // onMouseEnter={() => handleMouseEnter(droppedItem.id)} // Add event listener for mouse enter
                // onMouseLeave={handleMouseLeave}
                // className={`component ${isHovered ? 'hover-border-web-template' : ''}`} // Apply hover class conditionally
                >
                  <Component
                    widthVa={props.widthV}
                    keyValueId={droppedItem.id}
                    onComponentClick={handleComponentClick}
                    handleRemoveItem={handleRemoveItem}
                  />
                  {/* <button
                    className="component-delete-web-template"
                    id={droppedItem.id + "delete"}
                    onClick={() => handleRemoveItem(droppedItem.id)}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button> */}
                  {!componentsWithoutDelete.has(itemId) && (
                    <button
                      className="component-delete-web-template"
                      id={droppedItem.id + "delete"}
                      onClick={() => handleRemoveItem(droppedItem.id)}
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      ))
      }

    </div>
  );
};

export default Home;
